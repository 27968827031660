import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AddCategory from '../Category/AddCategory';
import ProductCategories from '../Category/ProductCategories';

import { categoriesLoad } from '../../../../actions/category';

import { Grid, Container } from '@mui/material';

const ProductQualities = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(categoriesLoad()); // categorías y marcas de producto
    }, []);

    return (
        <>
            <Container maxWidth="xxl">
                <Grid container spacing={6} sx={{ my: 3 }}>
                    <Grid item xs={12}>
                        <ProductCategories />
                    </Grid>
                    {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <AddCategory />
                    </Grid> */}
                </Grid>
            </Container>
        </>
    );
};

export default ProductQualities;