import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { basicMsg } from '../../../../actions/swal_msg';
import { deleteCategory, deleteSubcategory, saveSubcategory } from '../../../../actions/category';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
    Stack, Typography, Button, Grid, IconButton, ListItem, ListItemText,
    ListItemIcon, Checkbox, Card, CardMedia, CardActions, Paper, TextField,
    CardContent, CardHeader, Box, Chip, Alert,
    Divider,
    Tooltip,
    Menu,
    MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LabelIcon from '@mui/icons-material/Label';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ProductCategoryList = ({ _category, categories, setCategories, setDeleted, index }) => {
    const dispatch = useDispatch();
    const { _id, checked, category, url, subcategories, imgId } = _category;

    const [displayField, setDisplayField] = useState(false);
    const [fields, setFields] = useState({
        categoryField: '',
        subcategoryField: ''
    });
    const { categoryField, subcategoryField } = fields;

    useEffect(() => {
        setFields((e) => ({ ...e, categoryField: category }));
    }, [category]);

    // seleccionar categorías existentes
    const onChangeCheckbox = ({ target }) => {
        const checked = target.checked;
        if (!checked)
            setDeleted((e) => e.filter(e => e._id !== _id));
        else
            setDeleted((e => [...e, { _id, imgId, category }]));
        setCategories((e) => e.map(
            u => u._id === _id ?
                { ...u, checked } : u
        ));
    };

    // agregar subcategoría a categoría
    const addSubcategory = () => {
        if (subcategoryField.length > 2 && subcategoryField.length <= 60) {
            const subcategoryInArray = subcategories.find(
                e => e.toLowerCase() === subcategoryField.toLowerCase()
            );
            if (subcategoryInArray)
                basicMsg('La subcategoría ya está registrada');
            else {
                dispatch(saveSubcategory({
                    _id, category, subcategory: subcategoryField
                }, setFields));
                // setCategories((e) => e.map(
                //     u => u._id === _id ?
                //         {
                //             ...u, checked,
                //             subcategories: [...u.subcategories, subcategoryField]
                //         } : u
                // ));
                // setFields((e) => ({ ...e, subcategoryField: '' }));
            }
        }
    };

    // remueve subcategoría de categoría ya registrada
    const removeSubcategory = _subcategory => () => {
        dispatch(deleteSubcategory({
            _id, category, subcategory: _subcategory
        }));
        // setCategories((e) => e.map(
        //     u => u._id === _id ?
        //         {
        //             ...u, checked,
        //             subcategories: u.subcategories.filter(o => o !== _subcategory)
        //         } : u
        // ));
    };

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'category' && value.length <= 60)
            setFields((e) => ({ ...e, categoryField: value.toUpperCase() }));
        else if (name === 'subcategory' && value.length <= 60)
            setFields((e) => ({ ...e, subcategoryField: value.toUpperCase() }));
    };

    const deleteCategoryFn = () => {
        setAnchorEl(null);
        Swal.fire({
            title: `¿Eliminar categoría '${category}'?`,
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCategory({ _id, imgId }, categories));
            }
        });
    };

    // mostrar campo de categoria
    const showField = () => {
        setDisplayField(!displayField);
    };

    const updateMainCategory = () => {
        if (categoryField.length > 2 && categoryField.length <= 60) {
            setCategories((e) => e.map(
                u => u._id === _id ?
                    { ...u, category: categoryField } : u
            ));
            setDisplayField(false);
        }
    };

    //Abrir y cerrar menu para eliminar la categoria
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Draggable key={_id} draggableId={_id} index={index}>
            {(provided, snapshot) => (
                <Grid item xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} sx={{ display: 'flex' }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {/* <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Card elevation={0}>
                            <div style={{ position: "relative" }}>
                                <CardMedia style={{ height: "250px", paddingTop: "2%" }}
                                    component="img" title={ url } alt={`img-categoria-${url}`}
                                    image={ `${GOOGLE_DRIVE_CDN}${imgId} `} />
                                <div style={{
                                    position: "absolute",
                                    background: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    bottom: 10,
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    width: "100%",
                                    fontSize: "20px"
                                }}>
                                    { category }
                                </div>
                            </div>
                            <CardActions>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <IconButton aria-label="delete"
                                        onClick={ showField }>
                                        <EditIcon />
                                    </IconButton>
                                    <Button size="small" onClick={ deleteCategoryFn }>Eliminar</Button>
                                    <Checkbox color="default"
                                        checked={ checked }
                                        onChange={ onChangeCheckbox } />
                                </Stack>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            displayField &&
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={2}>
                                    <TextField sx={{ mb: 2 }}
                                        fullWidth
                                        name="category"
                                        label="Categoría principal"
                                        variant="outlined"
                                        value={ categoryField }
                                        inputProps={{ maxLength: 60 }}
                                        size="small"
                                        onChange={ handleInputChange } />
                                    <IconButton aria-label="delete"
                                        onClick={ updateMainCategory }>
                                        <CheckCircleIcon />
                                    </IconButton>
                                </Stack>
                        }

                        <Typography variant="body1">
                            Subcategorías:
                        </Typography>
                        {
                            subcategories.map((value) => (
                                <ListItem
                                    key={ value }
                                    disableGutters
                                    secondaryAction={
                                        <IconButton aria-label="comment" onClick={ removeSubcategory(value) }>
                                            <DeleteIcon />
                                        </IconButton>
                                    }>
                                    <ListItemIcon>
                                        <LabelIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={ value } />
                                </ListItem>
                            ))
                        }
                        <Grid container>
                            <Grid item xs={10}>
                                <TextField fullWidth
                                    name="subcategory"
                                    label="Subcategoría"
                                    variant="outlined"
                                    value={ subcategoryField }
                                    inputProps={{ maxLength: 60 }}
                                    size="small"
                                    onChange={ handleInputChange } />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton aria-label="add" onClick={ addSubcategory }>
                                    <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
                    {/* <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                        <CardHeader
                            action={
                                <Checkbox color="default"
                                    checked={checked}
                                    onChange={onChangeCheckbox} />
                            }
                            title={category}
                        />
                        <CardMedia
                            style={{ height: "250px", objectFit: 'contain' }}
                            component="img" title={url} alt={`img-categoria-${url}`}
                            image={`${GOOGLE_DRIVE_CDN}${imgId} `}
                        />
                        <CardContent>
                            {
                                displayField &&
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={2}>
                                    <TextField sx={{ mb: 2 }}
                                        fullWidth
                                        name="category"
                                        label="Categoría principal"
                                        variant="outlined"
                                        value={categoryField}
                                        inputProps={{ maxLength: 60 }}
                                        size="small"
                                        onChange={handleInputChange} />
                                    <IconButton aria-label="delete"
                                        onClick={updateMainCategory}>
                                        <CheckCircleIcon />
                                    </IconButton>
                                </Stack>
                            }

                            <Typography variant="body1" gutterBottom>
                                Subcategorías:
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', flexWrap: 'wrap', mb: 3 }}>
                                {
                                    subcategories.length > 0
                                        ?
                                        <>
                                            {
                                                subcategories.map((value) => (
                                                    <Chip key={value} label={value} onDelete={removeSubcategory(value)} />
                                                ))
                                            }
                                        </>
                                        :
                                        <Alert severity="info" sx={{width: '100%'}}>Ninguna subcategoria existente</Alert>
                                }
                            </Box>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextField fullWidth
                                        name="subcategory"
                                        label="Subcategoría"
                                        variant="outlined"
                                        value={subcategoryField}
                                        inputProps={{ maxLength: 60 }}
                                        size="small"
                                        onChange={handleInputChange} />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton aria-label="add" onClick={addSubcategory}>
                                        <AddBoxIcon fontSize="inherit" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                onClick={showField}>
                                <EditIcon />
                            </IconButton>
                        </CardActions>
                    </Card> */}
                    <Box className='card_admn_categories'>
                        <Box display='flex' justifyContent='flex-end' padding={1} gap='12px'>
                            <Box sx={{ my: 'auto' }}>
                                <Checkbox color="default" size='small'
                                    checked={checked}
                                    onChange={onChangeCheckbox} />
                            </Box>

                            <Box sx={{ my: 'auto' }}>
                                <IconButton size='small' onClick={handleClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={deleteCategoryFn}>
                                        <ListItemIcon>
                                            <DeleteIcon fontSize="small" />
                                        </ListItemIcon>
                                        Eliminar categoría
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{ p: 3 }}>
                            <Box className='top_card_categories' mb={3}>
                                <Box sx={{ my: 'auto' }}>
                                    <img className='img_categories' src={`${GOOGLE_DRIVE_CDN}${imgId} `} width='120px' style={{ borderRadius: '6px' }} />
                                </Box>
                                <Box className='name_content_categories' sx={{ my: 'auto' }}>
                                    {
                                        displayField ?
                                            <Stack
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                                spacing={1}>
                                                <TextField

                                                    name="category"
                                                    label="Categoría principal"
                                                    variant="outlined"
                                                    value={categoryField}
                                                    inputProps={{ maxLength: 60 }}
                                                    size="small"
                                                    onChange={handleInputChange} />
                                                <IconButton
                                                    onClick={() => { setDisplayField(false) }}>
                                                    <CancelIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={updateMainCategory}>
                                                    <CheckCircleIcon />
                                                </IconButton>
                                            </Stack>
                                            :
                                            <Box className='categories_name' sx={{ display: 'flex', gap: '10px' }}>
                                                <Typography variant='h6' sx={{ my: 'auto' }}>
                                                    {category}
                                                </Typography>
                                                <Tooltip title="Editar nombre de categoría">
                                                    <IconButton sx={{ my: 'auto' }} onClick={showField} size='small'><EditIcon fontSize='small' /></IconButton>
                                                </Tooltip>
                                            </Box>

                                    }
                                </Box>

                            </Box>

                            {/* <Divider sx={{ my: 3 }} /> */}

                            <Typography variant="body1" gutterBottom>
                                Subcategorías:
                            </Typography>
                            <Box className='subcategories_admn_content' mb={3}>
                                {
                                    subcategories.length > 0
                                        ?
                                        <>
                                            {
                                                subcategories.map((value) => (
                                                    <Chip key={value} label={value} onDelete={removeSubcategory(value)}
                                                        sx={{ backgroundColor: '#85c1e9', color: '#fff', fontWeight: '600' }}
                                                    />
                                                ))
                                            }
                                        </>
                                        :
                                        <Alert severity="info" sx={{ width: '100%' }}>Ninguna subcategoria existente</Alert>
                                }
                            </Box>

                            <Box sx={{ display: 'flex', gap: '15px' }}>
                                <TextField className='add_subcategory_input' sx={{ width: '40%' }}
                                    name="subcategory"
                                    label="Subcategoría"
                                    variant="outlined"
                                    value={subcategoryField}
                                    inputProps={{ maxLength: 60 }}
                                    size="small"
                                    onChange={handleInputChange} />

                                <IconButton aria-label="add" onClick={addSubcategory}>
                                    <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        </Box>

                    </Box>
                </Grid>
            )}
        </Draggable>
    );
};

export default ProductCategoryList;