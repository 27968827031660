import React, { useState, useEffect } from 'react';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";

//MUI
import {
    Alert, Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import InventoryIcon from '@mui/icons-material/Inventory';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//Router-dom
import { useNavigate } from 'react-router-dom';


//Actions
import { addItemsToCart, saveItemToCart } from '../../../actions/shop';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Swal
import { basicMsg } from '../../../actions/swal_msg';
import { shopProductModal } from '../../../actions/ui';

const CartButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

const CSButton = styled(Button)(({ theme }) => ({
    color: '#ffff',
    backgroundColor: '#0099DC',
    borderColor: '#0099DC',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#0076a9',
        color: '#ffff',
    },
}));

const AddToCartDialog = ({ variant }) => {
    const { _id: prod_var_id, item_promotion } = variant;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const {
        promotions // promociones de tienda
    } = useSelector(state => state.shop);
    const { cart: { action, messages } } = useSelector(state => state.ui);

    // diálogo
    const [open, setOpen] = useState(false);
    const [productPromotions, setProductPromotions] = useState([]);

    const [disabled, setDisabled] = useState(false);
    const [status, setStatus] = useState({
        lg_view: 12, sm_view: 12,
        multiple: false
    });
    const { lg_view, sm_view, multiple } = status;

    useEffect(() => {
        return () => {
            dispatch(shopProductModal({
                action: false, messages: []
            }));
        };
    }, []);
    
    // producto agregado a carrito
    useEffect(() => {
        if (action) {
            setOpen(true);
            dispatch(shopProductModal({
                action: false
            }));
        }
    }, [action]);

    useEffect(() => {
        setProductPromotions(
            promotions.filter(
                ({ products }) => products.find(e => e.prod_var_id === prod_var_id)
            )
        );
    }, [prod_var_id, promotions]);

    useEffect(() => {
        setStatus(
            productPromotions.length > 1 ?
                { lg_view: 10, sm_view: 8, multiple: true }
            :
                { lg_view: 12, sm_view: 12, multiple: false }
        );
    }, [productPromotions]);

    // activar promoción / agregar productos a carrito
    const activatePromotion = promo_id => () => {
        setDisabled(true);
        const PromotionSelected = productPromotions.find(e => e.promo_id === promo_id);
        if (PromotionSelected) {
            const { promo, pkg_qty, promo_ready, products } = PromotionSelected;
            if (!promo_ready) {
                // un sólo producto necesario para activar promoción
                if (['2x1', '4x3', 'Descuento%Producto', 'CompraX%Descuento', 'DescuentoPieza'].includes(promo)) {
                    const { prod_id, prod_var_id, quantity } = products[0];
                    const { qty, msg } = promoQty(promo, quantity, pkg_qty)
                    if (qty === 0) {
                        setDisabled(false);
                        dispatch(shopProductModal({
                            messages: [msg]
                        }));
                    } else {
                        const model = {
                            prod_id, prod_var_id, quantity: qty
                        };
                        dispatch(
                            saveItemToCart(model, { flag: true, message: [msg, 'Se ha activado la promoción'] }, setDisabled)
                        );
                    }
                } else {
                    const items = products.filter(e => e.quantity <= 0).map(({
                        prod_id, prod_var_id, title, variant_title
                    }) => ({
                        prod_id, prod_var_id, title, variant_title,
                        quantity: 1
                    }));
                    if (items.length > 0) {
                        dispatch(addItemsToCart(
                            items, setDisabled, () => {},
                            { flag: true, navigate }
                        ));
                        window.dataLayer.push({
                            event: 'add-to-cart'
                        });
                    } else {
                        setDisabled(false);
                        basicMsg('Parece ser que ya tiene los productos necesarios para esta promoción');
                    }
                }
            } else {
                setDisabled(false);
                basicMsg('La promoción ya cumple los requisitos. Diríjase a su carrito');
            }
        } else setDisabled(false);
    };

    // piezas a agregar
    const promoQty = (promo, qty, pkg_qty) => {
        switch(promo) {
            case '2x1':
                if (qty >= 2)
                    return {
                        qty: 0,
                        msg: 'Ya cumple las condiciones para aplicar esta promoción'
                    };
                else
                    return {
                        qty: 2 - qty,
                        msg: ''
                    };
            case '4x3':
                if (qty >= 4)
                    return {
                        qty: 0,
                        msg: 'Ya cumple las condiciones para aplicar esta promoción'
                    };
                else
                    return {
                        qty: 4 - qty,
                        msg: ''
                    };
            case 'CompraX%Descuento':
                if (qty >= pkg_qty)
                    return {
                        qty: 0,
                        msg: 'Ya cumple las condiciones para aplicar esta promoción'
                    };
                else
                    return {
                        qty: pkg_qty ? (pkg_qty - qty) : 1,
                        msg: ''
                    };
            case 'DescuentoPieza':
                if (qty >= 2)
                    return {
                        qty: 0,
                        msg: 'Ya cumple las condiciones para aplicar esta promoción'
                    };
                else
                    return {
                        qty: 2 - qty,
                        msg: ''
                    };
            default:
                return {
                    qty: 1,
                    msg: 'Agregado un producto adicional a carrito'
                };
        };
    };

    return (
        <>
            {/* Dialog agregar al carrito */}
            <Dialog
                fullWidth
                sx={{ zIndex: 1000 }}
                maxWidth={item_promotion.promo !== '' ? 'md' : 'sm'}
                open={open}
                onClose={ () => setOpen(false) }
            >
                <DialogTitle align='center'>Producto agregado al carrito</DialogTitle>
                <DialogContent>
                    {
                        // item_promotion.promo === '' &&
                        productPromotions.length > 0 &&
                            <Box sx={{ mb: 4 }}>
                                <svg className="check_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </Box>
                    }
                    {
                        productPromotions.length > 0 &&
                        <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;', mt: 2, borderRadius: '8px' }}>
                            <Alert
                                severity="info"
                                icon={<InventoryIcon />}
                            >
                                {
                                    productPromotions.length > 1 ?
                                        'Promociones disponibles'
                                    :
                                        'Promoción disponible'
                                }
                            </Alert>
                            <Box sx={{ p: 2 }}>
                                <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                                    {
                                        multiple &&
                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                                <IconButton className='promotions-swiper-button-prev'
                                                    size='small'>
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                            </Grid>
                                    }
                                    <Grid item xl={lg_view} lg={lg_view}
                                        md={lg_view} sm={sm_view}xs={sm_view}>
                                        <Swiper
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 1,
                                                },
                                                610: {
                                                    slidesPerView: 1,
                                                },
                                                800: {
                                                    slidesPerView: 1,
                                                },
                                                950: {
                                                    slidesPerView: 1,
                                                },
                                                1100: {
                                                    slidesPerView: 1,
                                                },
                                                1200: {
                                                    slidesPerView: 1,
                                                },
                                            }}
                                            spaceBetween={40}
                                            autoHeight={true}
                                            navigation={{
                                                nextEl: '.promotions-swiper-button-next',
                                                prevEl: '.promotions-swiper-button-prev',
                                            }}
                                            loop={multiple}
                                            pagination={{
                                                clickable: true,
                                                dynamicBullets: true,
                                            }}
                                            modules={[Navigation, Pagination]}
                                        >
                                            {
                                                productPromotions.map(({ promo_id, info, products, promo_ready }) => {
                                                    return (
                                                        <SwiperSlide key={promo_id}>
                                                            <Box className='prom_cont'
                                                                sx={{ display: 'flex', justifyContent: 'center', gap: '15px', my: 3 }}>
                                                                {
                                                                    products.map(({ prod_var_id, img: { id, alt } }, i) =>
                                                                        {
                                                                            i === products.length - 1 ?
                                                                                <Box sx={{ my: 'auto' }} key={ prod_var_id }>
                                                                                    <img
                                                                                        className="image_tog_prom" width='120' height="120"
                                                                                        style={{ objectFit: 'contain' }} alt={ alt }
                                                                                        src={ id }
                                                                                    />
                                                                                </Box>
                                                                            :
                                                                                <React.Fragment key={ prod_var_id }>
                                                                                    <Box sx={{ my: 'auto' }}>
                                                                                        <img
                                                                                            className="image_tog_prom" width='120' height="120"
                                                                                            style={{ objectFit: 'contain' }} alt={ alt }
                                                                                            src={ id }
                                                                                        />
                                                                                    </Box>
                                                                                    <Box sx={{ my: 'auto' }}>
                                                                                        <AddIcon fontSize='small' />
                                                                                    </Box>
                                                                                </React.Fragment>
                                                                        }
                                                                    )
                                                                }
                                                            </Box>
                                                            <Typography variant='body1' paragraph>
                                                                { info }
                                                            </Typography>
                                                            {
                                                                !promo_ready &&
                                                                    <CSButton sx={{ textTransform: 'none' }} variant='contained'
                                                                        disabled={ disabled } onClick={ activatePromotion(promo_id) }>
                                                                        Agregar promoción
                                                                    </CSButton>
                                                            }
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </Grid>
                                    {
                                        multiple &&
                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                                <IconButton className='promotions-swiper-button-next'
                                                size='small'>
                                                    <ChevronRightIcon />
                                                </IconButton>
                                            </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    }
                    {
                        (messages && messages.length > 0) &&
                            messages.map((e, i) =>
                                <Typography key={i}
                                    variant='body1'
                                    align='center' color='text.secondary'
                                    sx={{ fontWeight: '600', mt: 3 }}
                                >
                                    { e }
                                </Typography>
                            )
                    }
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', mt: '30px' }}>
                        <CartButton onClick={() => { navigate('/carrito') }} variant='contained'>Ver carrito</CartButton>
                        <CSButton onClick={() => setOpen(false)} variant='contained'>Seguir comprando</CSButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddToCartDialog;