// genera url a partir de string (producto/categoría)
export const generateURL = (_title) => {
    // url sin acentos / guiones por espacios / sin caracteres especiales / minúsculas
    let _url = _title.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/\s+/g,"-").replace(/[`~#$%&@¿?¡!|*/,.®<>\\;':"\/\[\]\|{}()=_+]/g, '-').toLowerCase();
    let last = ''; // último char en cadena
    let str = ''; // url procesada
    for (let i=0; i<_url.length; i++) {
        const char = _url.charAt(i);
        if (
            (i === 0 && char === '-') || // empieza con guión
            (i === _url.length-1 && char === '-') || // termina con guión
            (char === last && char === '-') || // caracter anterior es guión
            (char === _url.charAt(i+1) && char === '-') // caracter posterior es guión
        )
            // no incluimos el caracter en la cadena
            last = char;
        else { // se añade a la cadena
            str += char;
            last = char;
        }
    };
    // se han eliminado guiones '-' consecuentes
    return str;
};

export const getTitle = (title, var_title) => {
    return `${title} ${var_title}`.trim();
};

export const normalizeString = (str) => {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};