import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { valMsg } from '../../../actions/swal_msg';
import { shippingMethodsLoaded } from '../../../actions/shop_shipping';
import { saveShippingMethod, deleteShippingMethod } from '../../../actions/shipping';

import { regex_dec, regex_num } from '../../../constants/regex';
import { SHIPPING_METHOD_INIT } from '../../../constants/shop/shop_constants';

import {
    Grid, Button, List, ListItem, ListItemAvatar,
    ListItemText, Avatar, Typography, Alert,
    TextField, Checkbox, Container,
    FormControl, Popover, FormGroup, FormControlLabel,
    Switch, Divider,
    Box
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const ShippingMethods = () => {
    const dispatch = useDispatch();

    const { shipping } = useSelector(state => state.shop);
    // métodos de envío registrados
    const { methods: { full_list } } = shipping;

    // contador de métodos disponibles
    const [counter, setCounter] = useState(0);
    // método a registrar
    const [method, setMethod] = useState(SHIPPING_METHOD_INIT);
    const {
        partner, cost, delivery_time, description,
        min_day, max_day, odoo_id, for_refrigeration
    } = method;
    // métodos de envío seleccionados
    const [selected, setSelected] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const popover_id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        // nuevo método guardado, limpiar campos
        if (full_list.length > counter)
            setMethod(SHIPPING_METHOD_INIT);
        setCounter(full_list.length);
    }, [full_list]);

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'cost') {
            if (value === '' || regex_dec.test(value))
                setMethod({ ...method, [name]: value });
        } else if (name === 'odoo_id') {
            if (value === '' || regex_num.test(value))
                setMethod({ ...method, [name]: value });
        } else if ((['min_day', 'max_day'].includes(name))) {
            if (value > 0 && regex_num.test(value))
                setMethod({ ...method, [name]: value });
        } else if (name === 'for_refrigeration')
            setMethod({ ...method, for_refrigeration: !for_refrigeration });
        else setMethod({ ...method, [name]: value });
    };

    const handleCheckboxChange = (_checked, _id) => () => {
        if (_checked)
            setSelected(selected.filter(e => e !== _id));
        else setSelected([...selected, _id]);
        dispatch(shippingMethodsLoaded(
            full_list.map(e => e._id === _id ? { ...e, checked: !_checked } : e)
        ));
    };

    // validación formulario
    const formValidator = () => {
        const partner_len = partner.trim().length;
        const cost_len = cost.trim().length;
        const delivery_time_len = delivery_time.trim().length;
        const desc_len = description.trim().length;
        if (partner_len > 1 && partner_len <= 64) {
            if (cost_len > 0 && cost_len <= 8) {
                if (delivery_time_len > 2 &&
                    delivery_time_len <= 120) {
                    if (desc_len > 2 &&
                        desc_len <= 100) {
                        if (max_day > min_day)
                            return true;
                        else
                            valMsg('warning', 'Método de envío',
                                'El día mínimo de entrega es mayor al día máximo');
                    } else
                        valMsg('warning', 'Método de envío',
                            'Ingrese descripción de método');
                } else
                    valMsg('warning', 'Método de envío',
                        'Ingrese fecha de entrega');
            } else
                valMsg('warning', 'Método de envío',
                    'Ingrese costo de envío');
        } else
            valMsg('warning', 'Método de envío',
                'Ingrese la paquetería');
        return false;
    };

    // registrar nuevo método de envío
    const submit = () => {
        if (formValidator())
            dispatch(saveShippingMethod(method));
    };

    // eliminar método(s) de envío
    const deleteMethods = () => {
        dispatch(deleteShippingMethod(selected));
    };

    return (
        <Container maxWidth='xl' sx={{ mb: 5, mt: '90px' }}>
            <Typography variant='h5' paragraph>Métodos de envío ONL</Typography>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {
                            full_list.length > 0 ?
                                full_list.map(e =>
                                    <>
                                        <ListItem alignItems="flex-start" key={e._id}
                                            secondaryAction={
                                                <Checkbox checked={e.checked}
                                                    onChange={handleCheckboxChange(e.checked, e._id)} />
                                            }>
                                            <ListItemAvatar>
                                                <Avatar alt={`${e.partner} ${e.delivery_time}`}>
                                                    <LocalShippingIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${e.partner} (ID Odoo: ${e.odoo_id})`}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary">
                                                            {`$${e.cost}`}
                                                        </Typography>
                                                        {` Fecha de entrega: ${e.delivery_time}`}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider sx={{ my: 1.5 }} />
                                    </>
                                )
                                : <Alert severity="info">No tiene métodos de envío disponibles</Alert>
                        }
                    </List>

                    {
                        full_list.filter(e => e.checked).length > 0 &&
                        <Button
                            variant="contained"
                            sx={{ textTransform: 'none' }}
                            color='error'
                            onClick={deleteMethods}>
                            Eliminar métodos seleccionados
                        </Button>
                    }

                </Grid>

                {/* <Divider sx={{ m: 2 }} /> */}

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Box sx={{ p: 3, border: '1px solid #F8F9F9', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', borderRadius: '5px' }}>
                        <Typography variant='body1' sx={{ fontWeight: '600', fontSize: '18px' }} paragraph>
                            Registro de nuevo método de envio
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    name="partner"
                                    label="Compañía"
                                    variant="outlined"
                                    value={partner}
                                    inputProps={{ maxLength: 64 }}
                                    onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    name="cost"
                                    label="Costo de envío (sin IVA)"
                                    variant="outlined"
                                    value={cost}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    name="delivery_time"
                                    label="Tiempo de entrega (texto)"
                                    variant="outlined"
                                    value={delivery_time}
                                    inputProps={{ maxLength: 120 }}
                                    helperText="Ejemplo: 1 a 3 días"
                                    onChange={handleInputChange} />
                            </Grid>
                            {/* <TextField fullWidth
                            name="description"
                            label="Descripción de movimiento"
                            variant="outlined"
                            value={ description }
                            inputProps={{ maxLength: 100 }}
                            // helperText="Texto que se muestra en la factura como parte de la descripción"
                            helperText="Tipo de servicio"
                            onChange={ handleInputChange } /> */}

                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="number"
                                                label="Mínimo día de entrega"
                                                variant="outlined"
                                                name="min_day"
                                                value={min_day}
                                                size="small"
                                                InputProps={{ inputProps: { min: 1, maxLength: 3 } }}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                type="number"
                                                label="Máximo día de entrega"
                                                variant="outlined"
                                                name="max_day"
                                                value={max_day}
                                                size="small"
                                                InputProps={{ inputProps: { min: 1, maxLength: 3 } }}
                                                onChange={handleInputChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Button aria-describedby={popover_id} sx={{ mt: 1 }}
                                    variant="text"
                                    size='small'
                                    endIcon={<HelpOutlineIcon />}
                                    onClick={handleClick}>
                                    ¿Cómo llenar esta información?
                                </Button>
                                <Popover
                                    id={popover_id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <Typography sx={{ p: 2 }}>
                                        El mínimo y máximo día ayudan a estimar la fecha de entrega del pedido.
                                    </Typography>
                                </Popover>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth
                                    name="odoo_id"
                                    label="ID en Odoo"
                                    variant="outlined"
                                    value={odoo_id}
                                    inputProps={{ maxLength: 6 }}
                                    onChange={handleInputChange} />
                            </Grid>

                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch name='for_refrigeration'
                                                checked={for_refrigeration}
                                                onChange={handleInputChange}
                                                inputProps={{ 'aria-label': 'refrigeration-method' }} />
                                        }
                                        label="Seleccionar método por defecto para pedidos que necesiten refrigeración" />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{ textTransform: 'none' }}
                                    onClick={submit}>
                                    Agregar nuevo método
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ShippingMethods;