// import { msg } from "../../../actions/swal_msg";

import { htmlMsg } from "../../../actions/swal_msg";

// formulario para guardar nuevo cupón
export const form1Validator = ({
    name, description, _class, use_method, discount,
    restrictions, purchase_requirements,
}, setInputError) => {
    const { type, _value } = discount;
    const discount_num = Number(_value);

    const len1 = name.trim().length;
    const len2 = _value.trim().length;
    const len3 = description.trim().length;

    // establece errores en los campos
    setInputError(e => ({
        ...e,
        name_err: (len1 < 3 || len1 > 60)
            ? true : false,
        discount_err: (
            type === 'percentage' ? (len2 < 1 || len2 > 3) ? true : false
			: len2 > 0 ? false : true
        )
    }));
    if (len1 > 2 && len1 <= 30) {
        if (len3 > 3 && len3 <= 100) {
            if (_class === 'free_shipping') // cupon de envio gratis
                return req6(use_method, purchase_requirements, restrictions);
            else if (_class === 'order_discount') { // cupon de descuento en total de pedido
                if (type === 'percentage') { // descuento porcentual
                    if (_value !== '' && len2 <= 3 &&
                        discount_num > 0 && discount_num <= 100)
                        return req6(use_method, purchase_requirements, restrictions);
                    return { status: false, msg: 'Introduzca un % de descuento' };
                } else if (type === 'fixed') { // descuento fijo
                    if (_value !== '' && len2 > 0 && len2 <= 8)
                        return req6(use_method, purchase_requirements, restrictions);
                    return { status: false, msg: 'Introduzca un % de descuento' };
                }
            }
        } else return { status: false, msg: 'Introduzca descripción' };
    } else return { status: false, msg: 'Introduzca código de cupón' };
    return { status: false, msg: 'Complete información de cupón' };
};

const req1 = ({ active, amount }) => {
    const _amount = amount.trim().length;
    if (active) {
        if (amount !== '' && Number(amount) > 0 && _amount <= 10)
            return { status: true, msg: '' };
        else
            return { status: false, msg: 'Ingrese monto mínimo de compra' };
    } else
        return { status: true, msg: '' };
};

const req2 = ({ active, items_qty }) => {
    const _qty = items_qty.trim().length;
    if (active) {
        if (items_qty !== '' && Number(items_qty) > 0 && _qty <= 4)
            return { status: true, msg: '' };
        else
            return { status: false, msg: 'Ingrese cantidad mínima de artículos' };
    } else
        return { status: true, msg: '' };
};

const req3 = ({ active, list }, txt) => {
    if (active) {
        if (list.length > 0)
            return { status: true, msg: '' };
        else
            return { status: false, msg: `Seleccione por lo menos un${txt}` };
    } else
        return { status: true, msg: '' };
};

// validar límite de cupón
const req4 = ({ limited, quantity }) => {
    const _quantity = quantity.trim().length;
    if (limited) {
        if (quantity !== '' && Number(quantity) > 0
            && _quantity <= 4)
            return { status: true, msg: '' };
        return { status: false, msg: 'Introduzca límite de cupones' };
    } else return { status: true, msg: '' };
};

// validar requisitos de compra
const req5 = (
    {
        min_purchase_amount, minimum_items_qty,
        applies_to: { category, product }
    }, restrictions
) => {
    if (min_purchase_amount.active || minimum_items_qty.active || category.active || product.active) {
        const op1 = req1(min_purchase_amount);
        const op2 = req2(minimum_items_qty);
        const op3 = req3(category, 'a categoría');
        const op4 = req3(product, ' producto');
        if (op1.status && op2.status && op3.status && op4.status)
            return req4(restrictions);
    
        let txt = '';
        if (op1.msg) txt += `<li>${op1.msg}</li>`;
        if (op2.msg) txt += `<li>${op2.msg}</li>`;
        if (op3.msg) txt += `<li>${op3.msg}</li>`;
        if (op4.msg) txt += `<li>${op4.msg}</li>`;
        htmlMsg('info', 'Requisitos de compra',
        `<div style="text-align: left; padding-left: 0pt;"><ul>${txt}</ul></div>`);
        return { status: false, msg: '' };
    }
    return { status: false, msg: 'Debe seleccionar por lo menos un requisito de compra' };
};

// validar método de cupón
const req6 = (use_method, purchase_requirements, restrictions) => {
    if (use_method === 'by_code') { // descuento mediante cupon
        if (purchase_requirements.active)
            return req5(purchase_requirements, restrictions);
        else return req4(restrictions);
    } else if (use_method === 'auto') // descuento automatico
        return req5(purchase_requirements, restrictions);
    return { status: false, msg: 'Complete información de cupón' };
};