import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { gapi } from 'gapi-script';
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { signUp } from '../../../../actions/user';
import {
    // basicMsg,
    msg
} from '../../../../actions/swal_msg';

//CSS
import '../SignUp.css'

//IMG
// import logo_mobile from '../../../../assets/images/Logo.png'
// import logo_desktop from '../../../../assets/images/Logo_Nefropolis.png'

import { regex_email } from '../../../../constants/regex';

//MUI
import {
    FormControl, TextField, InputLabel, OutlinedInput, InputAdornment,
    IconButton, Container, Box, Typography, FormControlLabel, Checkbox,
    Button, Grid, Link,
    useMediaQuery
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const clientId = process.env.REACT_APP_CLIENT_ID;
const init = {
    username: '',
    email: '',
    password: ''
};

const benefits = [
    {
        icon: <LockIcon />,
        iconColor: '#45b39d',
        title: 'Seguridad mejorada',
        text: 'Protección y seguridad para tus datos y transacciones.',
    },
    {
        icon: <ShoppingCartCheckoutIcon />,
        iconColor: '#e67e22',
        title: 'Experiencia personalizada',
        text: 'Revisa tu historial de compras y obten recomendaciones personalizadas.',
    },
    {
        icon: <AddLocationAltIcon />,
        iconColor: '#e74c3c',
        title: 'Facilidad en el proceso de compra',
        text: 'Guarda tu información de envío para compras más rápidas.',
    },
    {
        icon: <NotificationsActiveIcon />,
        iconColor: '#a569bd',
        title: 'Notificaciones y actualizaciones',
        text: 'Recibe alertas sobre nuevos productos, restock de artículos, ventas flash, etc.',
    },
    {
        icon: <LocalShippingIcon />,
        iconColor: '#5499c7',
        title: 'Seguimiento de pedidos',
        text: 'Monitoreo del estado de tus pedidos en tiempo real.',
    },
]

const Registro = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [values, setValues] = useState(init);
    const { username, email, password } = values;
    const [togglePasword, setTogglepassword] = useState(false);

    const matches = useMediaQuery('(max-width: 991.5px)')
    const matches_p = useMediaQuery('(max-width: 950px)')

    useEffect(() => {
        const initClient = () => {
            gapi.auth2.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);

    // éxito respuesta de Google oAUth
    // const onSuccess = (res) => {
    //     dispatch(signUp({
    //         googleId: res.googleId,
    //         username: res.profileObj.name,
    //         email: res.profileObj.email,
    //         page: props.page
    //         // tokenId: res.tokenId
    //     }));

    // };
    // const onFailure = (err) => {
    //     basicMsg('No se pudo conectar con Google');
    // };

    // éxito respuesta registro con Facebook
    // const responseFacebook = (response) => {
    //     if (!response.status) {
    //         dispatch(signUp({
    //             facebookId: response.userID,
    //             username: response.name,
    //             email: response.email,
    //             page: props.page
    //         }));
    //     }
    // };

    // cambios formulario
    const handleChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
    };

    const submit = () => {
        if (formValidator())
            dispatch(signUp({
                ...values,
                googleId: '',
                facebookId: '',
                page: props.page
            }));
    };

    // validación formulario
    const formValidator = () => {
        const user_len = username.trim().length;
        const email_len = email.trim().length;
        const pwd_len = password.trim().length;
        if (user_len > 2 && user_len <= 100) {
            if (email_len > 5 && email_len <= 120
                && regex_email.test(email)) {
                if (pwd_len > 4 && pwd_len <= 60)
                    return true;
                else
                    msg('warning', 'Registro',
                        'Ingrese una contraseña segura');
            } else
                msg('warning', 'Registro',
                    'Ingrese su correo electrónico');
        } else
            msg('warning', 'Registro',
                'Ingrese un nombre de usuario');
        return false;
    };

    const togglePassword = () => {
        setTogglepassword(!togglePasword);
    };

    return (
        <div className='bg_signup'>
            <Container maxWidth='xxl' sx={{ py: matches_p ? 5 : 10 }} className='signup_container'>
                <Grid container spacing={6}>
                    <Grid item xl={7} lg={6.5} md={12} sm={12} xs={12} order={{ xl: 1, lg: 1, md: 2, sm: 2, xs: 2 }}>

                        <Typography variant='h5' color='text.secondary' className='benef_text' sx={{ fontWeight: 'bold', fontSize: '30px' }}>
                            Crea tu cuenta en Nefrópolis y disfruta de grandes beneficios:
                        </Typography>

                        <Box sx={{ mt: 5 }}>
                            <Grid container spacing={5}>
                                {
                                    benefits.map((res, index) => (
                                        <Grid item xl={4} lg={5} md={6} sm={6} xs={6} key={index}>
                                            <Box className='circle_icon'>
                                                <Box sx={{ color: res.iconColor }}>
                                                    {res.icon}
                                                </Box>
                                            </Box>

                                            <Box sx={{ mt: 2 }}>
                                                <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                                    {res.title}
                                                </Typography>
                                                <Typography variant='body1'>
                                                    {res.text}
                                                </Typography>
                                            </Box>

                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xl={5} lg={5.5} md={12} sm={12} xs={12} order={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
                        {
                            matches ?
                                <Typography variant='h5' color='text.secondary' sx={{ fontWeight: 'bold', mb: 3 }} textAlign='center'>
                                    Crear una cuenta
                                </Typography>
                                :
                                null
                        }
                        <Box className='form_signup'>
                            {/* Nombre */}
                            <FormControl fullWidth sx={{ mb: 2.5 }}>
                                <TextField id="" label="Nombre y apellidos" variant="outlined"
                                    name='username'
                                    value={username}
                                    onChange={handleChange} />
                            </FormControl>

                            {/* Correo electronico */}
                            <FormControl fullWidth sx={{ mb: 2.5 }}>
                                <InputLabel>Correo electrónico</InputLabel>
                                <OutlinedInput
                                    id="pass-input"
                                    type='email'
                                    placeholder=""
                                    label="Correo electrónico"
                                    name='email'
                                    value={email}
                                    onChange={handleChange}
                                />
                            </FormControl>

                            {/* Contraseña con metodo para mostrar y ocultar */}
                            <FormControl fullWidth>
                                <InputLabel>Contraseña</InputLabel>
                                <OutlinedInput
                                    id="pass-input1"
                                    type={togglePasword ? "text" : "password"}
                                    style={{ backgroundColor: '#fff' }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePassword}

                                                edge="end"
                                            >
                                                {togglePasword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Contraseña"
                                    name='password'
                                    value={password}
                                    onChange={handleChange}
                                />
                            </FormControl>

                            {/* Recordar contraseña */}
                            <Box>
                                <FormControlLabel control={<Checkbox defaultChecked={false} size="small" />} label="Recordar contraseña" />
                            </Box>

                            {/* button continuar con registro */}
                            <Box sx={{ mt: 2.5, textAlign: 'center' }}>
                                <Button variant='contained' sx={{ fontWeight: '600', width: '100%', height: '55px' }} onClick={submit}>
                                    <span style={{ fontSize: '13px' }}>Crear cuenta</span>
                                </Button>
                                {/* onClick={props.nextstep} */}
                            </Box>

                            {/* Link iniciar sesión */}
                            <Box sx={{ mt: 3 }}>
                                <Typography variant='body1' color='text.secondary' sx={{ fontStyle: 'italic' }}>
                                    Ya tienes cuenta? <u>
                                        <Link component="button"
                                            style={{ color: '#2699FB' }}
                                            onClick={() => {
                                                navigate("/login", { state: { page: props.page } })
                                            }}>Iniciar sesión
                                        </Link>
                                    </u>
                                </Typography>
                            </Box>

                            {/* Avisos de privacidad */}
                            <Box sx={{ mt: 3 }}>
                                <Typography variant='body1' color='text.secondary' sx={{ fontStyle: 'italic' }}>
                                    Al crear una cuenta aceptas las <u>
                                        <Link component="button"
                                            style={{ color: '#2699FB' }}
                                            onClick={() => {
                                                navigate("/terminos-y-condiciones")
                                            }}>condiciones de uso
                                        </Link>
                                    </u> y el <u>
                                        <Link component="button"
                                            style={{ color: '#2699FB' }}
                                            onClick={() => {
                                                navigate("/politica-de-privacidad")
                                            }}>aviso de privacidad
                                        </Link>
                                    </u> de Nefrópolis
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Registro;