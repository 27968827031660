import React, { useCallback, useEffect, useRef, useState } from 'react';

//CSS
import './ResetPwd.css'

//Router dom
import { useParams, useNavigate } from 'react-router-dom';

//helpers
import { request } from '../../../helpers/requestHelper';

//actions
import { msg as msgFn, basicMsg } from '../../../actions/swal_msg';

//Components
import RequestMessage from './RequestMessage';

//MUI
import {
    FormHelperText, InputLabel, OutlinedInput, InputAdornment,
    FormControl, Button, Grid, IconButton, Container, useMediaQuery,
    Box, Typography
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';

// estilos para mostrar/ocultar errores
const show_err = { display: "none" };
const hide_err = { display: "flex" };

const toggleInit = {
    pwd1: false,
    pwd2: false
};

const messages = {
    'default': 'No se ha recuperado la solicitud',
    'website-err': 'Ocurrió un incidente en sitio web',
    'server-err': 'Ocurrió un incidente',
    'not-found': 'Solicitud no encontrada',
    'request-expired': 'Su solicitud ha expirado',
    'pwd-reset': 'Ya ha restablecido su contraseña',
};

const ResetPwd = () => {
    const matches = useMediaQuery('(max-width: 950px)');

    const navigate = useNavigate();
    // referencias a campos
    const pwd1Ref = useRef();
    const pwd2Ref = useRef();
    // hash de solicitud
    const { hash } = useParams();

    // valores formulario
    const [values, setValues] = useState({
        password1: '',
        pwd1_err: false,
        password2: '',
        pwd2_err: false,
    });
    const { password1, pwd1_err, password2, pwd2_err } = values;
    // mostrar/ocultar contraseña
    const [pwdToggle, setPwdToggle] = useState(toggleInit);
    const { pwd1, pwd2 } = pwdToggle;

    // estado solicitud
    const [msg, setMsg] = useState('default');
    // información de solicitud
    const [requestId, setRequestId] = useState('');

    const fetch = useCallback(async () => {
        try {
            const resp = await request('user/reset-request', { hash }, 'POST');
            const body = await resp.json();
            if (body) {
                setMsg(body.msg); // estado
                if (body.status)
                    setRequestId(body.resp);
            }
        } catch (err) {
            setMsg('website-err');
        }
    });
    useEffect(() => {
        // recuperar datos de solicitud
        fetch();
    }, []);

    const handleChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        switch (name) {
            case 'password1':
                setValues({
                    ...values,
                    [name]: value,
                    pwd1_err: (value.length < 5 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'password2':
                setValues({
                    ...values,
                    [name]: value,
                    pwd2_err: (value !== password1) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // restablecer contraseña
    const submit = async () => {
        if (formValidator()) {
            try {
                const resp = await request('user/reset-pwd',
                    { _id: requestId, password1 }, 'POST');
                const body = await resp.json();
                if (body.status) {
                    msgFn('success', 'Contraseña restablecida',
                        'Ahora puede iniciar sesión en Nefrópolis');
                    setTimeout(() => {
                        navigate('/login');
                    }, 4000);
                } else basicMsg(messages[body.msg]);
            } catch (err) {
                basicMsg(messages['website-err']);
            }
        }
    };

    // validación formulario
    const formValidator = () => {
        if (msg === 'valid-request') {
            if (requestId !== '') {
                const pwd1_len = password1.length;
                const pwd2_len = password2.length;
                if (pwd1_len > 4 && pwd1_len <= 60) {
                    if (pwd2_len > 4 && pwd2_len <= 60
                        && password1 === password2)
                        return true;
                    else {
                        pwd2Ref.current.select();
                        setErrors('pwd2_err');
                    }
                } else {
                    pwd1Ref.current.select();
                    setErrors('pwd1_err');
                }
            } else basicMsg('No se pudo recuperar información de la solicitud');
        } else basicMsg(messages[msg]);
        return false;
    };

    // establece errores en los campos
    const setErrors = (key) => {
        setValues({
            ...values,
            [key]: true
        });
    };

    // ver/ocultar contraseñas
    const handlePwd1Toggle = () => {
        setPwdToggle({ ...pwdToggle, pwd1: !pwd1 });
    };
    const handlePwd2Toggle = () => {
        setPwdToggle({ ...pwdToggle, pwd2: !pwd2 });
    };
    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };

    return (
        <Container maxWidth='xxl' className="last-view" sx={{ mt: matches ? 5 : 18 }}>
            <RequestMessage msg={msg} />
            <Box className='resetPwd_box'>
                <Typography variant='h5' sx={{ fontWeight: '600', mb: 5 }}>Ingrese una nueva contraseña</Typography>
                <Grid container spacing={3}>
                    {/* input contraseña */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>
                                Contraseña nueva
                            </InputLabel>
                            <OutlinedInput
                                id="pwd1-input"
                                type={pwd1 ? 'text' : 'password'}
                                // placeholder="Contraseña nueva"
                                style={{ backgroundColor: '#fff' }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handlePwd1Toggle}
                                            onMouseDown={handleMouseDownPwd}
                                            edge="end">
                                            {pwd1 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                // startAdornment={
                                //     <InputAdornment position="start">
                                //         <i className="fa-solid fa-user"></i>
                                //     </InputAdornment>
                                // }
                                label="Contraseña nueva"
                                error={pwd1_err}
                                inputRef={pwd1Ref}
                                name="password1"
                                value={password1}
                                onChange={handleChange}
                            />
                            <FormHelperText error style={pwd1_err ? hide_err : show_err}>
                                Introduzca una contraseña
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    {/* confirmar contraseña */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>
                                Confirmar contraseña
                            </InputLabel>
                            <OutlinedInput
                                id="pwd2-input"
                                type={pwd2 ? 'text' : 'password'}
                                // placeholder="Confirme contraseña"
                                style={{ backgroundColor: '#fff' }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handlePwd2Toggle}
                                            onMouseDown={handleMouseDownPwd}
                                            edge="end">
                                            {pwd2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                // startAdornment={
                                //     <InputAdornment position="start">
                                //         <i className="fa-solid fa-user"></i>
                                //     </InputAdornment>
                                // }
                                label="Confirmar contraseña"
                                error={pwd2_err}
                                inputRef={pwd2Ref}
                                name="password2"
                                value={password2}
                                onChange={handleChange}
                            />
                            <FormHelperText error style={pwd2_err ? hide_err : show_err}>
                                {
                                    password2.length <= 0
                                        ?
                                        'Confirme su nueva contraseña'
                                        :
                                        password1 !== password2
                                            ?
                                            'La contraseñas no coinciden'
                                            :
                                            null
                                }
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    {/* Button submit */}
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='flex-end'>
                            <Button
                                sx={{ textTransform: 'none' }}
                                variant="contained"
                                onClick={submit}>
                                Restablecer contraseña
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default ResetPwd;