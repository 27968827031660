import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProductItem from './ProductItem';
import ProductItem2 from './ProductItem2';
import { normalizeString } from '../../../../helpers/admin/productHelper';

import {
    Typography, Input, InputAdornment, Box, Alert
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const ProductList = ({ list, setCoupon }) => {
    // lista de productos
    const { products } = useSelector(state => state.coupon);
    // lista que elimina productos ya seleccionados
    const [productList, setProductList] = useState([]);
    // productos filtrados
    const [allProducts, setAllProducts] = useState([]);
    
    // búsqueda de producto
    const [search, setSearch] = useState('');
    
    useEffect(() => {
        setProductList(products);
    }, [products]);
    
    // producto buscando
    const handleInputChange = ({ target }) => {
        setSearch(target.value);
        if (target.value !== '') filterProducts(target.value);
        else setAllProducts([]);
    };

    // filtrar productos por búsqueda
    const filterProducts = (value) => {
        // primero filter y luego set
        if (productList.length > 0) {
            const search_field = normalizeString(value);
            setAllProducts(productList.filter(({ full_title, keywords }) => {
                const title = normalizeString(full_title);
                return (
                    title.includes(search_field)
                    || keywords.includes(search_field)
                    || keywords.filter(e => e.indexOf(search_field) >= 0).length > 0
                ) ? true : false;
            }));
        }
    };

    return (
        <>
            {
                productList.length > 0 ?
                    <Box sx={{ my: 5 }}>
                        <Typography variant="subtitle1">
                            Buscar productos
                        </Typography>
                        <Input fullWidth sx={{ mb: 2 }}
                            placeholder="Buscar producto (título/categoría)"
                            value={ search }
                            onChange={ handleInputChange }
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                        <ProductItem
                            allProducts={ allProducts }
                            setAllProducts={ setAllProducts }
                            setSearch={ setSearch }
                            selectedProducts={ list }
                            setCoupon={ setCoupon }
                            setProductList={ setProductList }
                        />
                    </Box>
                :
                    <Alert severity="info">No hay productos disponibles.</Alert>
            }
            <ProductItem2
                selectedProducts={ list }
                setCoupon={ setCoupon }
            />
        </>
    );
};

export default ProductList;