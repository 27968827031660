import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import CouponCardMenu from './CouponCardMenu';
import { couponSelected } from '../../../actions/coupon';

import {
    Card, CardContent, CardHeader, IconButton, Stack,
    Divider, Typography, Checkbox, Box, Chip
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { styled } from '@mui/material/styles';

const CardContentCustom = styled(CardContent)
(`
    padding: 1;
    &:last-child {
        padding-bottom: 0;
    }
`);

const CouponCard = ({ list, coupon }) => {
    const dispatch = useDispatch();
    const {
        _id, name, _class, discount, use_method,
        purchase_requirements, restrictions,
        available, active_date, selected
    } = coupon;
    const { min_purchase_amount, minimum_items_qty, applies_to } = purchase_requirements;
    const { product } = applies_to;
    const { limited, quantity } = restrictions;
    const status = available ? '(Habilitado)' : '(Inhabilitado)';

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const aria_controls = `basic-coupon-menu-${_id}`;

    // abrir menú
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCheckboxChange = () => {
        dispatch(couponSelected(_id));
    };

    return (
        <>
            <Card sx={{ minWidth: 275, pb: 1 }}>
                <CardHeader avatar={
                    <LocalOfferIcon fontSize='large' />
                }
                    action={
                        list ?
                            <Checkbox checked={ selected }
                                onChange={ handleCheckboxChange } />
                            : <IconButton aria-label="settings"
                                aria-controls={ open ? { aria_controls } : undefined }
                                aria-haspopup="true"
                                aria-expanded={ open ? 'true' : undefined }
                                onClick={ handleClick }>
                                <MoreVertIcon />
                            </IconButton>
                    }
                    title={`${name} ${status}`}
                    subheader={
                        limited ? `${quantity} disponibles`
                        : 'Cupón ilimitado ∞'
                    } />
                <Divider sx={{ mx: 2 }} />
                <CardContentCustom>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Box sx={{ my: 'auto' }}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="body2" color="text.primary" gutterBottom>
                                    Disponible:
                                </Typography>
                                <Typography variant="caption" color="text.primary" gutterBottom>
                                    { active_date }
                                </Typography>
                            </Stack>
                        </Box>

                        <Box sx={{ my: 'auto' }}>
                            <Typography sx={{ fontSize: 12, mr: 1 }} color="text.secondary" gutterBottom>
                                {
                                    use_method === 'by_code' ?
                                        'Se aplica con código'
                                    :
                                        'Se aplica automáticamente'
                                }
                            </Typography>
                        </Box>

                        <Box sx={{ my: 'auto' }}>
                            <Stack direction="row" alignItems="flex-end">
                                {
                                    _class === 'order_discount' ?
                                        <>
                                            <Typography sx={{ fontSize: 12, mr: 1 }} color="text.secondary" gutterBottom>
                                                Descuento:
                                            </Typography>

                                            <Chip
                                                style={{ backgroundColor: "#2699FB", color: 'white' }}
                                                label={
                                                    discount.type === 'percentage' ?
                                                        `${discount._value}%`
                                                    :
                                                        `$${discount._value}`
                                                }
                                            />
                                        </>
                                    :
                                        <Chip
                                            style={{ backgroundColor: "#2699FB", color: 'white' }}
                                            label="Envío gratis"
                                        />
                                }
                            </Stack>
                        </Box>
                    </Box>
                    {
                        (
                            min_purchase_amount.active ||
                            minimum_items_qty.active ||
                            product.length > 0
                        ) &&
                            <Box>
                                <Typography variant="body2" gutterBottom>
                                    Requisitos:
                                </Typography>
                                {
                                    min_purchase_amount.active &&
                                        <Chip size="small"
                                            label={`Mínimo de compra: $${min_purchase_amount.amount}`} 
                                            variant="outlined"
                                        />
                                }
                                {
                                    minimum_items_qty.active &&
                                        <Chip size="small"
                                            label={`Mínimo de artículos: ${minimum_items_qty.items_qty}`}
                                            variant="outlined"
                                        />
                                }
                                {
                                    product.length > 0 &&
                                        <Chip size="small"
                                            label="Carrito debe tener ciertos productos"
                                            variant="outlined"
                                        />
                                }
                            </Box>
                    }
                </CardContentCustom>
            </Card>
            <CouponCardMenu
                coupon={ coupon }
                anchorEl={ anchorEl }
                setAnchorEl={ setAnchorEl }
            />
        </>
    );
};

export default CouponCard;