import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

//CSS
import './ProductListItem.css';
import no_image from '../../../../assets/images/banners/coleccion-todos1.jpg';

import {
  // activeVariants,
  productActive
} from '../../../../actions/product';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
  Box, Chip, Grid, IconButton, Typography
} from '@mui/material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';

// const spanItalic = {
//   fontStyle: 'italic',
//   marginLeft: '4px'
// };

export default function ProductListItemCard({ product, anchorEl, setAnchorEl }) {
  const dispatch = useDispatch();
  const { active } = useSelector(state => state.product);
  const {
    _id, title, category, prod_category, prod_variants
  } = product;

  const open = Boolean(anchorEl);
  const aria_controls = `product-list-menu-${_id}`;

  // const variantList = () => {
  //   dispatch(activeVariants({
  //     prod_id: _id, title, folderId,
  //     variants: prod_variants
  //   }));
  //   myRef.current.scrollIntoView();
  // };

  const handleSettingsClick = ({ currentTarget }) => {
    // cambió el producto activo
    if (JSON.stringify(active) !== JSON.stringify(product))
      dispatch(productActive(product));
    setAnchorEl(currentTarget);
  };

  return (
    <>

      <Grid item xxl={2.4} xl={3} lg={4} md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
        <Box sx={{ boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', borderRadius: '7px', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: .5 }}>
            <IconButton aria-label="settings"
              aria-controls={open ? { aria_controls } : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleSettingsClick}>
              <SettingsIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              {
                prod_variants.length > 0 &&
                  prod_variants[0].images.length > 0 ?
                  <img className='img_product_list_item'
                    src={`${GOOGLE_DRIVE_CDN}${prod_variants[0].images[0].id}`}
                    width="250px" height='250px' style={{objectFit: 'contain'}} alt={prod_variants[0].images[0].alt} />
                  :
                  <img className='img_product_list_item'
                    src={no_image}
                    width="250px" height='250px' style={{objectFit: 'contain'}} alt="producto-nefropolis" />
              }
            </Box>
            <Typography variant="body1" textAlign='center' sx={{ mt: 1 }}>
              {title}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom textAlign='center'>
              {
                prod_variants.length === 1 ?
                  `(1 variante)`
                  :
                  `(${prod_variants.length} variantes)`
              }
            </Typography>

            <Typography sx={{ fontSize: 14, fontWeight: '600' }} gutterBottom>
              Categorías:
            </Typography>

            <Box display='flex' justifyContent='flex-start' flexWrap='wrap' gap='10px' mb={2}>
              {
                (prod_category && prod_category.length > 0) ?
                  prod_category.map(({ category }, i) =>
                    (i === prod_category.length - 1) ?
                      <Chip key={i} label={category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()} sx={{backgroundColor: '#7fb3d5', color: '#fff'}} size='small' />
                      :
                      <Chip key={i} label={category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()} sx={{backgroundColor: '#7fb3d5', color: '#fff'}} size='small' />
                  )
                  :
                  (category && category.length > 0) ?
                    category.map((e, i) =>
                      (i === prod_category.length - 1) ?
                        <Chip key={i} label={e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()} sx={{backgroundColor: '#7fb3d5', color: '#fff'}} size='small' />
                        :
                        <Chip key={i} label={e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()} sx={{backgroundColor: '#7fb3d5', color: '#fff'}} size='small' />
                    )
                    :
                    ` N/A`
              }
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};