import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import BrandCard from './BrandCard';

import { Grid } from '@mui/material';

const BrandDragDrop = ({ brands, setBrands }) => {
    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = reorder(
            brands,
            result.source.index,
            result.destination.index
        );
      
        setBrands(items);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    return (
        <DragDropContext
            onDragEnd={ (result) => onDragEnd(result) }>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <Grid container
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        spacing={3}
                    >
                        {
                            brands.map((brand, index) => (
                                <BrandCard key={ brand._id }
                                    brand={ brand }
                                    setBrands={ setBrands }
                                    index={ index }
                                />
                            ))
                        }
                        { provided.placeholder }
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default BrandDragDrop;