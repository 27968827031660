import { types } from '../types/types';
import { protectedReq } from "../helpers/requestHelper";

import { basicMsg, msg } from './swal_msg';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

// cargar lista de cupones
export const couponList = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/list');
            const body = await resp.json();
            if (body.status)
                dispatch(couponsLoaded(body.resp));
        } catch (err) {
            msg('error', 'Cupones',
            'No se pudo recuperar la lista de cupones');
        };
    };
};

// lista de cupones obtenida
const couponsLoaded = (payload) => ({
    type: types.couponsLoaded, payload
});

// guardar cupón
export const couponSave = async (coupon) => {
    try {
        const {
            _class, name, description, discount: { type, _value },
            restrictions: { one_per_user, limited, quantity },
            purchase_requirements: {
                min_purchase_amount: {
                    active: active1, amount
                }, minimum_items_qty: {
                    active: active2, items_qty
                }, applies_to: { category, product }
            }
        } = coupon;
        const model = {
            ...coupon, name: name.trim(),
            description: description.trim(),
            discount: _class === 'free_shipping' ?
            { type: 'percentage', _value: '' } : {
                type: type.trim(),
                _value: _value.trim()
            },
            purchase_requirements: {
                min_purchase_amount: {
                    active: active1,
                    amount: active1 ? amount.trim() : '0'
                },
                minimum_items_qty: {
                    active: active2,
                    items_qty: active2 ? items_qty.trim() : '0'
                },
                applies_to: {
                    category: {
                        ...category,
                        list: category.active ? category.list : []
                    },
                    product: {
                        ...product,
                        list: product.active ? product.list : []
                    }
                }
                // applies_to: {
                //     category: {
                //         exclusive: false,
                //         list: category.list
                //     },
                //     product: {
                //         exclusive: false,
                //         list: product.list
                //     }
                // }
            },
            restrictions: {
                one_per_user, limited, quantity: limited ? quantity.trim() : '0'
            }
        };
        
        const route = _class === 'order_discount' ? 'coupon/save-class-one' : 'coupon/save-class-two';
        const resp = await protectedReq(route, model, 'POST');
        const body = await resp.json();
        if (body.status) return { status: true };
        else {
            if (body.msg === 'coupon-exists')
                msg('warning', 'Cupón',
                'Ya existe un cupón con el mismo código');
            else if (body.msg === 'miss')
                msg('warning', 'Cupón',
                'Faltan datos del cupón');
            else if (body.msg === 'server-err')
                msg('error', 'Cupón',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else msg('warning', 'Cupón',
                'Inténtelo de nuevo, por favor');
        }
        return { status: false };
    } catch (err) {
        msg('error', 'Cupón',
        'No se pudo agregar el cupón');
        return { status: false };
    };
};

// actualizar cupón
export const couponUpdate = (coupon, setInputErr) => {
    return async(dispatch) => {
        try {
            const {
                _class, name, description, discount: { type, _value },
                restrictions: { one_per_user, limited, quantity },
                purchase_requirements: {
                    min_purchase_amount: {
                        active: active1, amount
                    }, minimum_items_qty: {
                        active: active2, items_qty
                    }, active, applies_to: { category, product }
                }, start_date, end_date
            } = coupon;
            const model = {
                ...coupon, name: name.trim(),
                description: description.trim(),
                discount: _class === 'free_shipping' ?
                { type: 'percentage', _value: '' } : {
                    type: type.trim(),
                    _value: _value.trim()
                },
                purchase_requirements: {
                    min_purchase_amount: {
                        active: active1,
                        amount: active1 ? amount.trim() : '0'
                    },
                    minimum_items_qty: {
                        active: active2,
                        items_qty: active2 ? items_qty.trim() : '0'
                    },
                    active, applies_to: {
                        category: {
                            ...category,
                            list: category.active ? category.list : []
                        },
                        product: {
                            ...product,
                            list: product.active ? product.list : []
                        }
                    }
                },
                restrictions: {
                    one_per_user, limited, quantity: limited ? quantity.trim() : '0'
                }
            };
            
            const route = _class === 'order_discount' ? 'coupon/update-class-one' : 'coupon/update-class-two';
            const resp = await protectedReq(route, model, 'PUT');
            const body = await resp.json();
            if (body.status) {
                dispatch(couponUpdatedFn({
                    ...model,
                    purchase_requirements: {
                        min_purchase_amount: {
                            active: active1,
                            amount: active1 ? amount.trim() : ''
                        },
                        minimum_items_qty: {
                            active: active2,
                            items_qty: active2 ? items_qty.trim() : ''
                        },
                        active, applies_to: {
                            category: {
                                ...category,
                                list: category.active ? category.list : []
                            },
                            product: {
                                ...product,
                                list: product.active ? product.list : []
                            }
                        }
                    },
                    restrictions: {
                        one_per_user, limited, quantity: limited ? quantity.trim() : ''
                    },
                    active_date: `${format(new Date(start_date), "d 'de' MMMM yyyy", { locale: es })} - ${format(new Date(end_date), "d 'de' MMMM yyyy", { locale: es })}`
                }));
                setInputErr({
                    name_err: false,
                    discount_err: false
                });
            } else {
                if (body.msg === 'coupon-not-found')
                    basicMsg('No se encontró el cupón');
                else if (body.msg === 'server-err')
                    msg('error', 'Cupón',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Cupón',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Cupón',
            'No se pudo actualizar el cupón');
        };
    };
};

// eliminar cupón
export const couponDelete = (_id, handleClose) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/delete', { _id }, 'DELETE');
            const body = await resp.json();
            if (body.status) {
                handleClose();
                dispatch(couponDeletedFn(_id));
            } else {
                if (body.msg === 'not-found')
                    basicMsg('El cupón no existe');
                else if (body.msg === 'server-err')
                    msg('error', 'Cupón',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Cupón',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            basicMsg('No se pudo eliminar el cupón');
        };
    };
};

// cupón fue actualizado
const couponUpdatedFn = (payload) => ({
    type: types.couponUpdated, payload
});

// cupón fue eliminado
const couponDeletedFn = (id) => ({
    type: types.couponDeleted,
    payload: id
});

// cupón fue selecionado
export const couponSelected = (id) => ({
    type: types.couponSelected,
    payload: id
});

// cupón es editado
export const couponEdit = (coupon) => ({
    type: types.couponEdit,
    payload: coupon
});
export const couponCloseModal = () => ({ type: types.couponCloseModal });

// eliminar cupones seleccionados
export const couponSelectedDelete = (array) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/deleteSelected', { array }, 'DELETE');
            const body = await resp.json();
            if (body.status) dispatch(selectedDeleted(array));
            else {
                if (body.msg === 'not-found')
                    msg('warning', 'Cupón',
                    'Uno o más cupones no pudieron ser eliminados');
                else if (body.msg === 'empty-arr')
                    msg('warning', 'Cupón',
                    'Ningún cupón fue eliminado');
                else if (body.msg === 'server-err')
                    msg('error', 'Cupón',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Cupón',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            basicMsg('No se pudo eliminar los cupones seleccionados');
        }
    }
};

// cupones seleccionados fueron eliminados
const selectedDeleted = (array) => ({
    type: types.couponsSelectedDeleted,
    payload: array
});