// cupón
export const COUPON_INIT = {
    _id: '',
    name: '',
    description: '',
    _class: 'order_discount',
    discount: {
        type: 'percentage',
        _value: ''
    },
    use_method: 'by_code',
    purchase_requirements: {
        active: false,
        min_purchase_amount: {
            active: false,
            amount: ''
        },
        minimum_items_qty: {
            active: false,
            items_qty: ''
        },
        applies_to: {
            category: {
                active: false,
                exclusive: false,
                list: []
            },
            product: {
                active: false,
                exclusive: false,
                list: []
            }
        }
    },
    restrictions: {
        one_per_user: false,
        limited: false,
        quantity: ''
    },
    available: true,
    active_date: '',
    start_date: '',
    end_date: ''
};

export const CART_COUPON = {
    name: '',
    _class: 'order_discount',
    description: '',
    discount: {
        type: 'percentage',
        _value: ''
    }
};
