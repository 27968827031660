import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { categoriesLoad } from '../../../../actions/category';
import { loadProductList, productDefault } from '../../../../actions/product';

import ProductModal from './ProductModal';
import ProductListItem from './ProductListItem';
import ProductListMenu from './ProductListMenu';
// import VariantAdd from './VariantList/VariantAdd';
// import VariantItem from './VariantList/VariantItem';
import VariantModal from './VariantList/VariantModal';

import { Alert, Grid, Fab, InputAdornment, Container, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
// import useMediaQuery from '@mui/material/useMediaQuery';
import VariantDrawer from './VariantList/VariantDrawer';

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const matches = useMediaQuery('(max-width:600px)');
  // lista de productos y variantes activas
  const { products, activeVariants } = useSelector(state => state.product);

  const { prod_id, title, variants } = activeVariants;

  const [anchorEl, setAnchorEl] = useState(null);
  // búsqueda
  const [search, setSearch] = useState("");
  // productos filtrados por búsqueda
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    dispatch(productDefault()); // reset (cambiar funcion)

    dispatch(loadProductList()); // productos
    dispatch(categoriesLoad()); // categorías y marcas de producto
  }, []);

  useEffect(() => {
    filterProducts(search);
  }, [products]);

  const handleOpen = () => {
    navigate('/nefro-admin/producto-agregar');
  };

  const handleSearchChange = ({ target }) => {
    const _value = target.value;
    setSearch(_value);
    if (products.length > 0) {
      filterProducts(_value);
    }
  };

  // filtrar productos de acuerdo a campo de texto
  const filterProducts = (_value) => {
    if (_value.trim() === '') {
      setFiltered(products);
    }

    else {
      const _search = _value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      setFiltered(products.filter((e) =>
        e.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
        ||
        e.prod_variants.find(u => u.variant_title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search))
        ||
        e.category.find(u => u.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search))
      ));
    }
  };

  const myRef = useRef(null);

  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <>
      <Container maxWidth='xl' sx={{ mt: 12 }} ref={myRef}>
        {/* <Input
          fullWidth
          id="search_into"
          placeholder="Buscar producto..."
          value={search}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        /> */}
        <TextField variant='outlined' value={search} onChange={handleSearchChange} placeholder='Buscar producto...' fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          InputLabelProps={{
            shrink: false
          }}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
          }}
        />
      </Container>

      <Container maxWidth='xxxl' sx={{ my: 5 }}>
        <Grid container spacing={4}>
          {
            products.length <= 0 ?
              <Grid item xs={12}>
                <Alert severity="info">
                  No tiene productos en tienda
                </Alert>
              </Grid>
              :
              filtered.map(product =>
                <ProductListItem
                  key={product._id}
                  product={product}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  // myRef={myRef}
                />
              )
          }
        </Grid>
      </Container>

      <ProductModal />
      <VariantModal />
      <ProductListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} setOpenDrawer={setOpenDrawer} />
      <VariantDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        product={{
          prod_id, title
        }}
        variants={variants}
        prod_id={prod_id}
        title={title}
      />

      <Fab
        color='primary'
        aria-label="add" sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1
        }}
        onClick={handleOpen}>
        <AddIcon />
      </Fab>
    </>
  );
};

export default ProductList;