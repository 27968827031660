import React from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Swal
import Swal from 'sweetalert2';

//Actions
import { productDelete, productEdit, activeVariants } from '../../../../actions/product';

//MUI
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

const ProductListMenu = ({ anchorEl, setAnchorEl, setOpenDrawer }) => {
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.product);
    const { _id, title, folderId, prod_variants } = active;
    
    const open = Boolean(anchorEl);

    // editar producto
    const editProduct = () => {
        setAnchorEl(null);
        dispatch(productEdit());
    };

    //Eliminar producto
    const deleteProduct = () => {
        setAnchorEl(null);
        Swal.fire({
            title: `¿Deseas eliminar el producto ${title}?`,
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(productDelete(_id, title, folderId, prod_variants));
            }
        });
    };

    //Lista de variantes
    const variantList = () => {
        dispatch(activeVariants({
            prod_id: _id, title, folderId,
            variants: prod_variants
        }));
        setAnchorEl(null);
        setOpenDrawer(true)
    };

    // cerrar menú
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Menu id="product-list-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-settings',
            }}>
            <MenuItem onClick={editProduct}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
            </MenuItem>
            <MenuItem onClick={deleteProduct}>
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Eliminar</ListItemText>
            </MenuItem>
            <MenuItem onClick={variantList}>
                <ListItemIcon>
                    <AutoAwesomeMotionIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Mostrar variantes</ListItemText>
            </MenuItem>
        </Menu>
    );
};

export default ProductListMenu;