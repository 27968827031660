import React from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';

import { couponDelete, couponEdit } from '../../../actions/coupon';

import { Menu, MenuItem } from '@mui/material';

const CouponCardMenu = ({ coupon, anchorEl, setAnchorEl }) => {
    const dispatch = useDispatch();
    const { _id, name } = coupon;
    const open = Boolean(anchorEl);

    // editar cupón
    const editCoupon = () => {
        setAnchorEl(null);
        dispatch(couponEdit(coupon));
    };

    const deleteCoupon = () => {
        setAnchorEl(null);
        Swal.fire({
            title: `Deseas eliminar el cupón "${name}"?`,
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed)
                dispatch(couponDelete(_id, () => {}));
        });
    };

    // cerrar modal
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Menu id="basic-coupon-menu"
            anchorEl={ anchorEl }
            open={ open }
            onClose={ handleClose }
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={ editCoupon }>Editar</MenuItem>
            <MenuItem onClick={ deleteCoupon }>Eliminar</MenuItem>
      </Menu>
    );
};

export default CouponCardMenu;