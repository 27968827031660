import React from 'react';

import { basicMsg } from '../../../../actions/swal_msg';

import { Typography, Grid, Button, Box } from "@mui/material";

// allProductos => productos filtrados
// selectedProducts => productos ya seleccionados
const ProductItem = ({
    allProducts, setAllProducts, setSearch, selectedProducts, setCoupon, setProductList
}) => {
    // seleccionar producto
    const selectProduct = _prod_var_id => () => {
        const j = selectedProducts.findIndex(({ variant_id }) => variant_id === _prod_var_id);
        if (j > -1)
            basicMsg('Producto ya seleccionado');
        else {
            const item = allProducts.find(e => e.prod_var_id === _prod_var_id);
            if (item) {
                const { prod_var_id, full_title, img: { id } } = item;
                setCoupon(e => ({
                    ...e,
                    purchase_requirements: {
                        ...e.purchase_requirements,
                        applies_to: {
                            ...e.purchase_requirements.applies_to,
                            product: {
                                ...e.purchase_requirements.applies_to.product,
                                list: [...e.purchase_requirements.applies_to.product.list,
                                    {
                                        variant_id: prod_var_id,
                                        full_title,
                                        image_id: id
                                    }
                                ]
                            }
                        }
                    }
                }));
                
                setSearch('');
                setAllProducts([]);
                // eliminar de lista productos ya seleccionados
                setProductList(e => (
                    e.filter((prod_var_id) => prod_var_id !== _prod_var_id)
                ));
            }
        }
    };

    return (
        <Grid container spacing={4} sx={{ mb: 4 }}>
            {
                allProducts.map(({ prod_var_id, full_title, img }) =>
                    <Grid item xl={6} lg={12} md={12} sm={12} xs={12} key={prod_var_id}>
                        <Box sx={{
                            display: 'flex', justifyContent: 'flex-start', gap: '15px',
                            borderRadius: '5px', padding: '10px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)'
                        }}>
                            <Box sx={{ my: 'auto' }}>
                                <img width='120' height="120"
                                    style={{ objectFit: 'contain' }}
                                    src={ img.id }
                                    alt={ img.alt }
                                />
                            </Box>
                            <Box sx={{ my: 'auto' }}>
                                <Typography component="div" variant="h6">
                                    { full_title }
                                </Typography>
                                <Button size="small" variant='outlined'
                                    onClick={ selectProduct(prod_var_id) }>
                                    Seleccionar
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default ProductItem;