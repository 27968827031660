import React from 'react'

//MUI
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

//Components
import VariantItem from './VariantItem'
import VariantAdd from './VariantAdd';

const VariantDrawer = ({ openDrawer, setOpenDrawer, product, variants, prod_id, title }) => {
    return (
        <Drawer
            variant="temporary"
            open={openDrawer}
            onClose={() => { setOpenDrawer(false) }}
            PaperProps={{
                sx: { width: "400px" },
            }}
            sx={{ zIndex: 2 }}
            ModalProps={{
                keepMounted: true
            }}
        >
            <Box sx={{ p: 2, mb: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px', mb: 3 }}>
                    <Stack direction="row" alignItems="center" gap={1} sx={{ my: 'auto' }}>
                        <AutoAwesomeMotionIcon />
                        <Typography variant="h6">Variantes</Typography>
                    </Stack>
                    <IconButton onClick={() => { setOpenDrawer(false) }} size='small' sx={{ my: 'auto' }}>
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                </Box>
                <Typography variant='body1'>
                    Variantes del producto: <br></br> <span style={{fontSize: '20px', fontWeight: '600'}}>{product.title}</span>
                </Typography>
            </Box>
            {
                prod_id !== '' ?
                    variants.length > 0 ?
                        <>
                            {
                                variants.map((variant, i) =>
                                    <VariantItem key={variant._id}
                                        pos={i + 1}
                                        product={{
                                            prod_id, title
                                        }}
                                        variant={variant}
                                        setOpenDrawer={setOpenDrawer}
                                    />
                                )
                            }
                        </>
                        :
                        <>
                            <Typography variant="overline" display="block" gutterBottom>
                                Producto sin variantes
                            </Typography>

                            <VariantAdd />
                        </>
                    :
                    <>
                        <Typography variant="overline" display="block" gutterBottom>
                            Seleccione producto para editar
                        </Typography>

                    </>
            }
            <Box sx={{ my: 2, p: 2, textAlign: 'center' }}>
                <VariantAdd />
            </Box>
        </Drawer>
    )
}

export default VariantDrawer