import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { NavContext } from "../../../context/NavContext";

import { gapi } from 'gapi-script';
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import CodeLoginModal from "./CodeLoginModal";

import {
    // basicMsg,
    msg
} from '../../../actions/swal_msg';
import { loginModalToggle } from "../../../actions/ui";
import {
    // facebookLogin, googleLogin,
    login, resetRequest
} from '../../../actions/user';

import './login.css';

//IMG
// import logo_mobile from '../../../assets/images/Logo.png';
// import logo_desktop from '../../../assets/images/Logo_Nefropolis.png';

//MUI react
import {
    Container, FormHelperText, Box, FormControlLabel, Checkbox,
    Typography, Dialog, Button, TextField, IconButton, InputLabel,
    FormControl, DialogTitle, OutlinedInput, DialogActions,
    DialogContent, InputAdornment, DialogContentText, Stack,
    Divider
} from "@mui/material";
import MailLockIcon from '@mui/icons-material/MailLock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const clientId = process.env.REACT_APP_CLIENT_ID;

// estilos para mostrar/ocultar errores
const show_err = { display: "none" };
const hide_err = { display: "flex" };

const EMAIL = {
    email2: '',
    email2_err: false,
};

const valuesInit = {
    email: '',
    password: '',
    remember: false,
    showPassword: false
};

const LoginComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const { setValue, setAnchorUser, setAnchorMenu, setAnchorEl } = useContext(NavContext);

    // referencias a campos
    const eInputRef = useRef();
    const pInputRef = useRef();
    const e2InputRef = useRef();
    // estado de modal
    const { loginModal } = useSelector(state => state.ui);

    // restablecimiento de contraseña
    const [_email, setEmail] = useState(EMAIL);
    const { email2, email2_err } = _email;

    // valores de formulario
    const [values, setValues] = useState(valuesInit);
    const { email, password, remember } = values;

    // errores en campos
    const [inputErr, setInputErr] = useState({
        email_err: false,
        pwd_err: false
    });
    const { email_err, pwd_err } = inputErr;

    const [dialogData, setDialogData] = useState({
        sent: false, // código enviado a correo
        status: false, // mostrar diálogo
        title: 'Recibir código a correo',
        text: 'Escribe la dirección de correo electrónico que usaste para registrarte o realizar un pedido con nosotros.',
        loading1: false,
        loading2: false
    });

    useEffect(() => {
        const initClient = () => {
            gapi.auth2.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);

    useEffect(() => {
        // se envió correo, restablecer valores
        if (!loginModal)
            setEmail(EMAIL);
    }, [loginModal]);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //Dialog para recuperar contraseña
    const handleClickOpen = () => {
        dispatch(loginModalToggle(true));
    };
    const handleClose = () => {
        dispatch(loginModalToggle(false));
    };

    // restablecer contraseña
    const resetPassword = () => {
        if (!email2_err)
            dispatch(resetRequest(email2));
    };

    // cambios formulario
    const handleChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
        switch (name) {
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'password':
                setInputErr({
                    ...inputErr,
                    pwd_err: (value.length < 5 ||
                        value.length > 60) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // cambios correo electrónico de modal
    const handleEmailChange = ({ target }) => {
        const value = target.value;
        setEmail({
            email2: value,
            email2_err: (value.length < 6 ||
                value.length > 100) ? true : false
        });
    };

    // inicio de sesión
    const submit = e => {
        e.preventDefault();
        if (formValidator()) {
            dispatch(login({
                ...values,
                page: state ? state.page : 'mi-cuenta'
            }));
            setValue('/mi-cuenta');
            setAnchorUser(null);
            setAnchorMenu(null);
            setAnchorEl(null);
        }
    };

    // validación formulario
    const formValidator = () => {
        const email_len = email.trim().length;
        const pwd_len = password.trim().length;
        if (email_len > 5 && email_len <= 100) {
            if (pwd_len > 4 && pwd_len <= 60)
                return true;
            else {
                pInputRef.current.select();
                msg('warning', 'Inicio de sesión',
                    'Ingrese su contraseña');
            }
        } else {
            eInputRef.current.select();
            msg('warning', 'Inicio de sesión',
                'Ingrese su correo electrónico');
        }
        // establece errores en los campos
        setInputErr({
            ...inputErr,
            email_err: (email_len < 6 ||
                email_len > 100) ? true : false,
            pwd_err: (pwd_len < 5 ||
                pwd_len > 60) ? true : false
        });
        return false;
    };

    // éxito respuesta de Google oAUth
    // const onSuccess = (res) => {
    //     dispatch(googleLogin({
    //         googleId: res.googleId,
    //         email: res.profileObj.email,
    //         username: res.profileObj.name,
    //     }));
    // };

    // fallo respuesta de Google oAUth
    // const onFailure = (err) => {
    //     basicMsg('No se pudo conectar con Google');
    // };

    // éxito respuesta inicio con Facebook
    // const responseFacebook = (response) => {
    //     if (!response.status) {
    //         dispatch(facebookLogin({
    //             facebookId: response.userID,
    //             email: response.email,
    //             username: response.name
    //         }));
    //     }
    // };

    return (
        <div className='bg_login'>
            <Container maxWidth='lg' sx={{ py: 10}}>
                <form onSubmit={submit} className='form_login'>
                    <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 3 }}>
                        Iniciar sesión
                    </Typography>
                    {/* Correo electronico */}
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel>
                            Correo electrónico
                        </InputLabel>
                        <OutlinedInput
                            id="email-input"
                            style={{ backgroundColor: '#fff' }}
                            label="Correo electrónico"
                            error={email_err}
                            inputRef={eInputRef}
                            name="email"
                            value={email}
                            onChange={handleChange}
                        />
                        <FormHelperText error style={email_err ? hide_err : show_err}>
                            Ingrese su correo electrónico
                        </FormHelperText>
                    </FormControl>

                    {/* Contraseña con metodo para mostrar y ocultar */}
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel>Contraseña</InputLabel>
                        <OutlinedInput
                            id="pass-input"
                            type={values.showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            error={pwd_err}
                            inputRef={pInputRef}
                            name='password'
                            value={password}
                            onChange={handleChange}
                        />
                        <FormHelperText error style={pwd_err ? hide_err : show_err}>
                            Ingrese su contraseña
                        </FormHelperText>
                    </FormControl>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Box>
                            <FormControlLabel onChange={handleChange} control={<Checkbox defaultChecked={remember} size="small" />} label="Recordar contraseña" />
                        </Box>
                        <Box sx={{ mt: .8 }}>
                            <Link to={""} onClick={handleClickOpen}>
                                Olvidé mi contraseña
                            </Link>
                        </Box>
                        {/* Dialog para recuperar contrasñea */}
                        <Dialog open={loginModal} onClose={handleClose}
                            sx={{ zIndex: 1000 }}>
                            <DialogTitle>¿Has olvidado la contraseña?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Escribe la dirección de correo electrónico que usaste para registrarte. Te enviaremos
                                    un correo electrónico con instrucciones sobre cómo restablecer tu contraseña.
                                </DialogContentText>
                                <Box sx={{ mt: 2 }}>
                                    <TextField
                                        fullWidth
                                        id=""
                                        label="Correo electrónico"
                                        type="email"
                                        variant="standard"
                                        error={email2_err}
                                        helperText={email2_err ? "Ingrese su correo electrónico" : ""}
                                        inputRef={e2InputRef}
                                        name="email2"
                                        value={email2}
                                        onChange={handleEmailChange}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancelar</Button>
                                <Button onClick={resetPassword}>Enviar</Button>
                            </DialogActions>
                        </Dialog>
                        {/* Fin dialog para recuperar contraseña */}
                    </Box>

                    <Stack spacing={2} sx={{mt: 1}}>
                        <Button type="submit" variant='contained'
                            sx={{ width: '100%', height: '55px', fontWeight: '600' }}>
                            Iniciar sesión
                        </Button>
                        <Divider className="login_divider">
                            <Typography sx={{ pl: 1, pr: 1 }}>o</Typography>
                        </Divider>
                        <Button variant="outlined" endIcon={<MailLockIcon />}
                            sx={{ width: '100%', height: '50px', fontWeight: '600', textTransform: 'none' }}
                            onClick={() => setDialogData(e => ({ ...e, status: true }))}>
                            Recibir código a correo
                        </Button>
                    </Stack>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant='body2' color='text.secondary' sx={{ fontStyle: 'italic' }}>
                            ¿No tienes cuenta? <Link to="/signup" style={{ color: '#2699FB' }}>Registrate</Link>
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant='body2' color='text.secondary' sx={{ fontStyle: 'italic' }}>
                            Al continuar aceptas las <Link to="/terminos-y-condiciones" style={{ color: '#2699FB' }}>condiciones de uso</Link> y
                            el <Link to="/politica-de-privacidad" style={{ color: '#2699FB' }}>aviso de privacidad</Link> de Nefrópolis
                        </Typography>
                    </Box>
                </form>
            </Container>

            <CodeLoginModal
                dialogData={dialogData}
                setDialogData={setDialogData}
                eInputRef={eInputRef}
                email={email}
                email_err={email_err}
                handleChange={handleChange}
            />
        </div>
    );
};

export default LoginComponent;