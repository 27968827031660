import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';

import { getCategoryURL } from '../../../helpers/shop/shopHelper';

import ShopFilter from './ShopFilter';
import ShopProducts from './ShopProducts';
import SubcategorySidebar from './SubcategorySidebar';
import CategoryContainer from '../Shop/CategoryContainer';

import {
    Container, Breadcrumbs, Grid, Typography, useMediaQuery
} from '@mui/material';

import {
    ALL_CATEGORIES, ALL_SUBCATEGORIES, CATEGORY_DEFAULT
} from '../../../constants/shop/shop_constants';

const ShopCategory = () => {
    const navigate = useNavigate();
    const { category_url, subcategory_url } = useParams();
    const matches = useMediaQuery('(max-width: 950px)');

    // categorías y productos de tienda
    const { categories, products } = useSelector(state => state.shop);

    // filtro de productos según categoría/subcategoría
    const [filteredProducts, setFilteredProducts] = useState([]);

    // categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_DEFAULT);
    const { category, url, subcategory, subcategories } = selectedCategory;

    const [selectedIndex, setSelectedIndex] = useState(0);

    // la ruta (categoría) ha cambiado
    useEffect(() => {
        if (categories.length > 0) {
            const found = categories.find(e => e.url === category_url);
            if (found) {
                const { subcategories } = found;
                if (subcategory_url && subcategories.length > 0) {
                    const i = subcategories.findIndex(item => getCategoryURL(item) === subcategory_url);
                    if (i > -1) // subcategoría existe
                        setSelectedCategory(e => ({
                            ...e, ...found,
                            subcategory: subcategories[i]
                        }));
                    else {
                        navigate(`/tienda/categoria/${category_url}`, { replace: true });
                        setSelectedCategory(e => ({
                            ...e, ...found,
                            subcategory: ALL_SUBCATEGORIES
                        }));
                    }
                } else
                    setSelectedCategory(e => ({
                        ...e, ...found,
                        subcategory: ALL_SUBCATEGORIES
                    }));
            }
        }
    }, [category_url, categories]);

    useEffect(() => {
        if (subcategory === 'VER TODO')
            setSelectedIndex(-1);
        else {
            const i = subcategories.findIndex(item => getCategoryURL(item) === subcategory_url);
            if (i > -1) // subcategoría existe
                setSelectedIndex(i);
            else
                setSelectedIndex(-1);
        }
    }, [subcategory]);
    
    // productos actualizados / subcategoría ha cambiado
    // (filtrar productos)
    useEffect(() => {
        if (category === ALL_CATEGORIES) {
            const filtered = products.filter(e =>
                e.prod_category.some(
                    u => (
                        subcategory === ALL_SUBCATEGORIES ? true :
                        u.subcategories.some(a => a === subcategory)
                    )
                )
            ).sort( // ordenar según posición en categoría guardada en producto
                (a, b) => {
                    const catA = a.position.categories.find(u => u.url === category_url);
                    const catB = b.position.categories.find(u => u.url === category_url);
                    if (!catA || !catB) return 1;
                    return catA.position < catB.position ? -1 : 1
                }
            );
            setFilteredProducts(filtered);
        } else {
            const filtered = products.filter(e =>
                e.prod_category.some(
                    u => u.category === category && (
                        subcategory === ALL_SUBCATEGORIES ? true :
                        u.subcategories.some(a => a === subcategory)
                    )
                )
            );
            // ordenar según posición en categoría guardada en producto
            const sorted = filtered.sort(
                (a, b) => {
                    const catA = a.position.categories.find(u => u.url === category_url);
                    const catB = b.position.categories.find(u => u.url === category_url);
                    if (!catA || !catB) return 1;
                    return catA.position < catB.position ? -1 : 1
                }
            );
            setFilteredProducts(sorted);
        }
    }, [products, category, subcategory]);

    const myRef = useRef(null);

    const cat_ref_mat = useMediaQuery('(max-width: 991px)');

    return (
        <>
            {/* Productos */}
            <Container maxWidth='xxl' sx={{ mt: matches ? 5 : 18 }} ref={cat_ref_mat ? null : myRef}>
                <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>
                    {category}
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit"
                        to="/tienda">Tienda
                    </Link>
                    <Typography color="text.primary">Categorías</Typography>
                </Breadcrumbs>

                {
                    cat_ref_mat ?
                        <div ref={myRef}></div>
                        :
                        null
                }

                {/* Filtros ordenar por... */}
                <ShopFilter
                    filteredProducts={filteredProducts}
                    setFilteredProducts={setFilteredProducts}
                />
                {/* fin filtros ordenar por... */}


                <Grid container
                    spacing={3.5}
                    justifyContent="center">
                    {/* Subcategorías - sidebar */}
                    {
                        subcategories.length > 0 &&
                        <SubcategorySidebar
                            url={ url }
                            selectedIndex={ selectedIndex }
                            subcategories={ subcategories }
                            setSelectedCategory={ setSelectedCategory }
                        />
                    }

                    {/* Productos */}
                    <ShopProducts
                        myRef={myRef}
                        filteredProducts={filteredProducts}
                        category={category}
                    />
                </Grid>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 7 }} className="last-view">
                <Typography variant='h5' sx={{ fontWeight: 'bold' }}
                    textAlign={'center'} paragraph>
                    Explorar otras categorías
                </Typography>
                <CategoryContainer />
            </Container>
            {/* Fin Productos */}
        </>
    );
};

export default ShopCategory;