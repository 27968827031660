import axios from 'axios';

import { uiLoading2 } from './ui';
import { types } from '../types/types';
import { basicMsg, htmlMsg, msg as swalMsg, posMsg } from './swal_msg';

import { logout } from './user';
import { generateURL } from '../helpers/admin/productHelper';
import { checkUserSession } from '../helpers/admin/sessionHelper';
import { axiosProtectedReq, protectedReq } from '../helpers/requestHelper';
import { variantInit2 } from '../constants/constants';

const base_url = process.env.REACT_APP_NEFRO_API_URL;

// cargar lista de productos (módulo admin)
export const loadProductList = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('product/list');
            const body = await resp.json();
            if (body.status)
                dispatch(productListLoaded(body.resp));
            else {
                const { msg } = body;
                if (checkUserSession(msg)) { // sesión activa
                    if (msg === 'server-err')
                        swalMsg('error', 'Lista de productos',
                        'Ocurrió un incidente. Intente de nuevo, por favor');
                } else // cerrar sesión
                    dispatch(logout());
            }
        } catch (err) {
            swalMsg('error', 'Lista de productos',
            'No se puede mostrar la lista de productos');
        };
    };
};

// producto fue agregado
const productListLoaded = (obj) => ({
    type: types.productListLoaded,
    payload: obj
});

// añadir producto y variantes
export const addProduct = (Model, navigate) => {
    return async(dispatch) => {
        try {
            dispatch(uiLoading2(true));

            // retiramos la propiedad 'pos'
            const { pos, additional_images, variants, ...ProductModel } = Model;

            let imagesArr = []; // imágenes de variantes
            let messages = []; // mensajes de respuesta

            // separamos archivos de imágenes de variante
            const VariantModel = variants.map(v => {
                // retiramos propiedad 'key' e 'images'
                const { key, images, ...variant } = v;
                imagesArr.push(images);
                return variant;
            });
            const resp = await protectedReq('product/add', {
                ...ProductModel, variants: VariantModel // actualizamos modelo
            }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { product, html } = body.resp;
                const { imagesFolderId, prod_variants } = product;

                let variantImgs = []; // datos de variantes con imágenes
                if (prod_variants.length > 0) {
                    // datos e imágenes de variantes
                    prod_variants.forEach(({ _id, folderId }, i) => {
                        if (folderId)
                            variantImgs.push({
                                _id, folderId, pos: i,
                                files: imagesArr[i] // incluimos imágenes para subida
                            });
                    });
                    // envío de imágenes a backend para subida
                    const messages1 = await uploadVariantsImages(variantImgs);
                    messages = [...messages, ...messages1];
                    if (imagesFolderId) {
                        const { msg: messages2 } = await uploadProductImages(product, additional_images);
                        messages = [...messages, ...messages2];
                    }
                }
                // información de backend
                let txt = '';
                for (const e of html) {
                    txt += e;
                };
                for (const e of messages) {
                    txt += e;
                };
                const icon = txt !== '' ? 'success' : 'info';

                dispatch(uiLoading2(false));

                htmlMsg(icon, 'Producto registrado',
                `<div style="text-align: left; padding-left: 0pt;"><ul>
                ${txt}</ul></div>`);
                navigate('/nefro-admin/productos');
            } else {
                dispatch(uiLoading2(false));

                const { msg, resp } = body;
                if (checkUserSession(msg)) { // sesión activa
                    if (msg === 'miss')
                        basicMsg('Falta información del producto');
                    else if (msg === 'product-exists')
                        basicMsg('Producto ya se encuentra registrado');
                    else if (msg === 'server-err')
                        swalMsg('warning', 'Incidente al guardar producto',
                        'Intente de nuevo, por favor');
                    else {
                        if (resp.html && resp.html.length > 0)
                            htmlMsg('warning', 'Producto no registrado',
                            `<div style="text-align: left; padding-left: 0pt;"><ul>${
                                resp.html.map(e => e)
                            }</ul></div>`);
                        else
                            basicMsg('Producto no registrado');
                    }
                } else // cerrar sesión
                    dispatch(logout());
            }
        } catch (err) {
            dispatch(uiLoading2(false));
            swalMsg('error', 'Producto',
            'No se pudo registrar el producto');
        };
    };
};

// subir imágenes de variantes al registrar producto
const uploadVariantsImages = async (variants) => {
    // variantes e imágenes
    let messages = [];

    for (const e of variants) {
        const { files, ...variant } = e;
        const data = new FormData();
        const files_data = files.map(({ alt }) => alt); // propiedad alt de imágenes
        data.append('variant', JSON.stringify({ ...variant, files_data }));
        files.forEach(({ img }) => {
            data.append('file', img);
        });
        try {
            const url = `${base_url}/product/variant/images-upload`;
            const resp = await axios.post(url, data);
            if (resp) {
                const { resp: { msg } } = resp.data;
                messages = [...messages, ...msg];
            } else
                messages = [...messages, '<li>Incidente en petición para subida de imágenes de variantes</li>'];
        } catch (err) {
            messages = [...messages, '<li>Error al subir imágenes de variantes</li>'];
        };
    };
    return messages;
};

// subir imágenes adicionales de producto
const uploadProductImages = async ({ _id, imagesFolderId }, images) => {
    const data = new FormData();
    data.append('product_id', _id);
    data.append('folderId', imagesFolderId);
    data.append('file', images);
    images.forEach(img => {
        data.append('file', img);
    });
    const url = 'product/additional-images-upload';
    try {
        const body = await axiosProtectedReq(url, data, 'POST');
        if (body.status) {
            const { msg, imagesId } = body.resp;
            return { msg, imagesId };
        } else return ['<li>Incidente en petición para subida de imágenes adicionales</li>'];
    } catch (err) {
        return ['Error al realizar petición para subida de imágenes adicionales'];
    };
};

// subir imágenes de variante en edición
const appendVariantImages = async (dispatch, model, newImages) => {
    const { folderId, title, prod_id, _id } = model;
    let count = 0;
    let success = 0;
    let messages = [];

    for (let [i, file] of newImages.entries()) {
        count++;
        const data = new FormData();
        data.append('folderId', folderId);
        data.append('title', title);
        data.append('prod_id', prod_id);
        data.append('var_id', _id);
        data.append('alt', file.alt);
        data.append('pos', i);
        data.append('file', file.img);
        const url = `product/variant/images-append`;
        try {
            const body = await axiosProtectedReq(url, data, 'POST');
            if (body.status) {
                const { id } = body.resp;
                success++;
                model.images = [...model.images, { id, alt: file.alt }];
            } else {
                if (body.msg === 'server-err')
                    messages = [...messages, 'Problema en servidor'];
                else messages = [...messages, body.msg];
            }
        } catch (err) {};
    };
    if (success === count) {
        posMsg('success', 'Variante actualizada. Imágenes subidas');
        dispatch(v_updated(model));
    } else {
        let txt = '';
        for (const e of messages) {
            txt += e;
        };
        htmlMsg('warning', 'Imágenes de variante',
        `<div style="text-align: left; padding-left: 0pt;"><ul>${txt}</ul></div>`);
    }
};

// subir imágenes de nueva variante
const uploadVariantImages = async ({ _id, folderId }, images) => {
    const data = new FormData();
    data.append('folderId', folderId);
    data.append('_id', _id);
    data.append('alt_array', JSON.stringify(images.map(({ alt } ) => alt)));
    // for (const image of images) {
    //     data.append('file', image.img);
    // };
    images.forEach(({ img }) => {
        data.append('file', img);
    });
    const url = 'product/variant/images-upload';
    try {
        const body = await axiosProtectedReq(url, data, 'POST');
        if (body.status)
            return body.resp;
        else {
            if (body.msg === 'server-err')
                swalMsg('error', 'Subida de imágenes',
                'Problema con servidor');
            else if (body.msg === 'request-err')
                swalMsg('error', 'Subida de imágenes',
                'Problema con servidor');
            else
                swalMsg('warning', 'Nueva variante',
                'Incidente al subir imágenes');
            return [];
        }
    } catch (err) {
        swalMsg('error', 'Nueva variante',
        'Error al enviar imágenes');
        return [];
    };
};

// editar producto
export const productEdit = () => ({ type: types.productEdit });

// producto activo
export const productActive = (obj) => ({
    type: types.productActive,
    payload: obj
});

// producto ya no está siendo editado
export const productCloseModal = () => ({ type: types.productCloseModal });

// actualizar producto
export const productUpdate = (
    product, allCategories, additionalImages, deletedImages,
    { setDeletedImages, setAdditionalImages, setLoading }
) => {
    return async(dispatch) => {
        try {
            let moreImages = []; // nuevas imágenes adicionales
            const { _id, images } = product;
            // filtrar categorías/subcategorías seleccionadas
            const prod_category = allCategories.filter(e => e.checked).map(
                ({ category, url, subcategories }) => ({
                    category, url, subcategories: subcategories.filter(a => a.checked).map(
                        ({subcategory }) => subcategory
                    )
                })
            );
            const resp = await protectedReq('product/update', { ...product, prod_category, deletedImages }, 'PUT');
            const body = await resp.json();
            if (body.status) {
                const { html, folderId, revFolderId, imagesFolderId } = body.resp;
                let messages = [...html];

                if (additionalImages.length > 0) {
                    if (imagesFolderId) {
                        const { msg: messages1, imagesId } = await uploadProductImages(
                            { _id, imagesFolderId },
                            additionalImages
                        );
                        messages = [...messages, ...messages1];
                        moreImages = [...moreImages, ...imagesId];
                    } else messages = [...messages, '<li>Carpeta para imágenes adicionales no existe. Imágenes no se subirán</li>'];
                }
                let txt = '';
                for (const e of messages) {
                    txt += e;
                };
                if (txt !== '')
                    htmlMsg('info', 'Producto actualizado',
                    `<div style="text-align: left; padding-left: 0pt;"><ul>${
                        txt
                    }</ul></div>`);
                else
                posMsg('success', 'Producto actualizado');
                // actualizar valores de variante
                dispatch(updated({
                    ...product,  images: [...images, ...moreImages],
                    folderId, revFolderId, imagesFolderId
                }));
                setDeletedImages([]);
                setAdditionalImages([]);
            } else {
                if (body.msg === 'product-not-found')
                    basicMsg('No se encontró el producto');
                else if (body.msg === 'not-updated')
                    swalMsg('warning', 'Actualización',
                    'No se actualizó el producto');
                else if (body.msg === 'server-err')
                    swalMsg('error', 'Actualización producto',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else swalMsg('warning', 'Actualización producto',
                    'Inténtelo de nuevo, por favor');
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            swalMsg('error', 'Actualización',
            'No se pudo actualizar el producto');
        };
    };
};

// eliminar producto
export const productDelete = (_id, title, folderId, prod_variants) => {
    return async(dispatch) => {
        try {
            const variants_id = prod_variants.map(e => e._id);
            const resp = await protectedReq('product/delete', { _id, folderId, variants_id }, 'DELETE');
            const body = await resp.json();
            if (body.status) {
                if (body.msg === 'success')
                    posMsg('success', 'Producto (y variantes) eliminado');
                else posMsg('warning', 'Producto eliminado. Una o más variantes no fueron eliminadas');
                dispatch(deleted(_id));
            }
            else {
                if (body.msg === 'miss')
                    swalMsg('warning', 'Producto',
                    'Falta información del producto');
                else if (body.msg === 'not-deleted')
                    swalMsg('warning', 'Producto',
                    `${title} no fue eliminado`);
                else if (body.msg === 'server-err')
                    swalMsg('error', 'Producto',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else swalMsg('warning', 'Producto',
                    'No hemos podido eliminar el producto');
            }
        } catch (error) {
            basicMsg('No se pudo eliminar el producto');
        };
    };
};

// producto fue actualizado
const updated = (obj) => ({
    type: types.productUpdated,
    payload: obj
});

// producto fue eliminado
const deleted = (id) => ({
    type: types.productDeleted,
    payload: id
});

// mostrar variantes de producto seleccionado
export const activeVariants = (obj) => ({
    type: types.activeVariants,
    payload: obj
});

// editar variante
export const variantEdit = (obj) => ({
    type: types.variantEdit,
    payload: obj
});

// añadir variante de producto
export const addVariant = (
    prod_id, mainFolderId, model, batches, { setOpenVariantDialog, setValues }
) => {
    return async(dispatch) => {
        try {
            const { images, ...obj } = model;
            const resp = await protectedReq('product/variant/add',
                { ...obj, prod_id, mainFolderId, batches }, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { _id, folderId } = body.resp;
                // envío de imágenes a backend para subida
                const variant_images = await uploadVariantImages({ _id, folderId }, images);
                dispatch(v_saved({
                    ...obj, _id, folderId, images: variant_images, batches
                }));
                setOpenVariantDialog(false); // cerrar diálogo
                setValues(variantInit2); // restablecer valores
            } else {
                const title = 'Nueva variante';
                if (body.msg === 'server-err')
                    swalMsg('warning', title,
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else if (body.msg === 'variant-not-saved')
                    swalMsg('warning', title,
                    'La variante de producto no fue guardada');
                else if (body.msg === 'product-not-saved')
                    swalMsg('warning', title,
                    'La variante de producto no fue guardada');
                else if (body.msg === 'not-found')
                    swalMsg('warning', title,
                    'No se encontró el producto');
                else if (body.msg === 'folder-not-created')
                    swalMsg('warning', title,
                    'La carpeta de la variante no fue creada');
                else swalMsg('error', title,
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            swalMsg('error', 'Nueva variante',
            'No se pudo registrar la variante');
        };
    };
};

// actualizar variante
export const variantUpdate = (model, newImages, deletedImages, _batches) => {
    return async(dispatch) => {
        try {
            const { batches, title, prod_id, ...variant } = model;
            const resp = await protectedReq('product/variant/update',
                { ...variant, deletedImages, _batches }, 'PUT');
            const body = await resp.json();
            if (body.status) {
                if (newImages.length > 0) // envío de nuevas imágenes de variante a backend para subida
                    await appendVariantImages(
                        dispatch,
                        {
                            ...variant, title, prod_id,
                            batches: [...batches, ..._batches]
                        },
                        newImages
                    );
                else {
                    posMsg('success', 'Variante actualizada');
                    dispatch(v_updated({ ...variant, batches: [...batches, ..._batches] }));
                }
            } else {
                if (body.msg === 'not-found')
                    swalMsg('warning', 'Variante',
                    'No se encontró la variante');
                else if (body.msg === 'not-updated')
                    swalMsg('warning', 'Variante',
                    'No se actualizó la variante');
                else if (body.msg === 'server-err')
                    swalMsg('error', 'Variante',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else swalMsg('warning', 'Variante',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            swalMsg('error', 'Variante',
            'No se pudo actualizar la variante');
        };
    };
};

// eliminar variante
export const variantDelete = (model) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('product/variant/delete', model, 'DELETE');
            const body = await resp.json();
            if (body.status) {
                if (body.msg === 'prod-not-upd')
                    swalMsg('success', 'Variante',
                    'Variante eliminada. Producto no actualizado');
                else posMsg('success', 'Variante e imágenes eliminadas');
               dispatch(v_deleted(model._id));
            } else {
                if (body.msg === 'not-found')
                    swalMsg('error', 'Variante',
                    'No se encontró la variante');
                else if (body.msg === 'not-deleted')
                    swalMsg('error', 'Variante',
                    'No se pudo eliminar la variante');
                else if (body.msg === 'server-err')
                    swalMsg('error', 'Variante',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else swalMsg('warning', 'Variante',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (error) {
            basicMsg('No se pudo eliminar la variante');
        }
    }
};

// variante guardada en BD, agregar a arreglo en producto
const v_saved = (payload) => ({
    type: types.variantSaved, payload
});

// variante fue actualizada
const v_updated = (obj) => ({
    type: types.variantUpdated,
    payload: obj
});

// variante fue eliminada
const v_deleted = (id) => ({
    type: types.variantDeleted,
    payload: id
});

// variante ya no está siendo editada
export const variantCloseModal = () => ({ type: types.variantCloseModal });

// abrir snackbar en módulo de productos
// const productSnackbarOpen = () => ({
//     type: types.productSnackbarOpen
// });

// cerrar snackbar en módulo de productos
export const productSnackbarClose = () => ({
    type: types.productSnackbarClose
});

// abrir menú de producto en lista
export const setMenuAnchorEl = (obj) => ({
    type: types.productMenuAnchorEl,
    payload: obj
});

// registrar producto
export const productDefault = () => ({ type: types.productDefault });

// carga masiva de productos provenientes de .xlsx
export const massiveLoad = async(array) => {
    try {
        const filtered = array.filter(e => e.se_puede_vender === 'SI');
        const products = filtered.map(({
            id, categorias, titulo, titulo_variante, precio_no_iva, iva,
            stock, clave_sat, codigo_barras, info, claveUnidad,
            unidad, palabras_clave, destacado, descripcion, disponible,
            id_odoo
        }) => {
            const title = titulo ? titulo : '';
            // categorías extras
            const categories = categorias ? categorias.split(',').map(e => e.trim()) : [];
            return {
                id: id ? id : '',
                title,
                url: generateURL(title),
                variant_title: titulo_variante ? titulo_variante : '',
                price: precio_no_iva ? precio_no_iva : 0,
                iva: iva ? iva : 0,
                stock: stock ? stock : 0,
                info: info ? info : '',
                sat_key: clave_sat ? clave_sat : '',
                claveUnidad: claveUnidad ? claveUnidad : '',
                unidad: unidad ? unidad : '',
                barcode: codigo_barras ? codigo_barras : '',
                category: categories,
                description: descripcion ? descripcion : 'Sin descripción',
                keywords: palabras_clave ? palabras_clave.split(',').map(e => e.trim()): [],
                odoo_id: id_odoo ? id_odoo : '',
                featured: (['SI','Sí','Si'].includes(destacado)) ? true : false,
                available: (['SI','Sí','Si'].includes(disponible)) ? true : false
            }
        });
        const resp = await protectedReq('product/massive-load', products, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { registered, failed } = body.resp;
            // const { _inserted, _updates, failed_insertions, failed_updates } = body.resp;
            // const failMsg = failed_insertions > 0 ? `Productos no registrados: ${failed_insertions}` : 'Sin errores en registro de productos';
            // const failMsg2 = failed_updates > 0 ? `Productos no actualizados: ${failed_updates}` : 'Sin errores en actualización de productos';
            // htmlMsg('success', 'Carga masiva',
            // `<p>Carga masiva finalizada</p><h5>Resumen de carga:</h5>
            // <h6>Productos registrados: ${_inserted}</h6>
            // <h6>Productos actualizados: ${_updates}</h6>
            // <h6>${failMsg}</h6>
            // <h6>${failMsg2}</h6>`);
            htmlMsg('success', 'Carga masiva',
                `<p>Carga masiva finalizada</p><h5>Resumen de carga:</h5>
                <h6>Productos registrados: ${registered.length}</h6>
                <h6>Operaciones fallidas: ${failed.length}</h6>`
            );
        } else
            swalMsg('error', 'Carga masiva',
            'Ocurrió un incidente. Intente de nuevo, por favor');
        return body.status;
    } catch (err) {
        swalMsg('error', 'Carga masiva',
        'No se pudo hacer carga masiva de productos');
        return false;
    }
};