import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import './ProductCategory.css';
import ProductCategoryList from './ProductCategoryList';

import { basicMsg } from '../../../../actions/swal_msg';
import { deleteCategories, updateAllCategories } from '../../../../actions/category';

import {
    List, ListItem, ListItemText, ListItemButton, ListItemAvatar,
    Avatar, Alert, Stack, Typography, Button, Grid,
    Box,
    Snackbar,
    IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';
import AddCategory from './AddCategory';

const ProductCategories = () => {
    const dispatch = useDispatch();
    // categorías de producto
    const { categories } = useSelector(state => state.category);

    // categorías
    const [allCategories, setCategories] = useState([]);
    // categorías removidas
    const [deleted, setDeleted] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCategories(categories.map(e => ({ ...e, checked: false })));
        setDeleted([]);
    }, [categories]);

    // eliminar una o más categorías
    const deleteMany = () => {
        if (deleted.length > 0)
            dispatch(deleteCategories(deleted));
        setOpenAlert(true)
    };

    const updateAll = async () => {
        if (allCategories.length <= 0)
            basicMsg('No hay categorías disponibles');
        else {
            setLoading(true);
            dispatch(updateAllCategories(allCategories, setLoading));
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = reorder(
            allCategories,
            result.source.index,
            result.destination.index
        );

        setCategories(items);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const [openDialog, setOpenDialog] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)

    return (
        <div>
            {
                allCategories.length > 0 ?
                    <Typography variant="h5" gutterBottom>
                        LISTA DE CATEGORÍAS
                    </Typography>
                    :
                    <Alert severity="info">No hay categorías de producto guardadas</Alert>
            }

            <Box sx={{ display: 'flex', gap: '10px', my: 2 }}>
                {
                    allCategories.length > 0 &&
                    <LoadingButton
                        sx={{ textTransform: 'none' }}
                        endIcon={<UpgradeIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        onClick={updateAll}>
                        Actualizar categorías existentes
                    </LoadingButton>
                }

                <Button variant='outlined' sx={{ textTransform: 'none' }} endIcon={<AddIcon />} onClick={() => { setOpenDialog(true) }}>Añadir nueva categoria</Button>
            </Box>

            <AddCategory openDialog={openDialog} setOpenDialog={setOpenDialog} />

            {
                deleted.length > 0 &&
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Categorías a eliminar:
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px', flexWrap: 'wrap', mb: 2 }}>
                        {
                            deleted.map(({ _id, imgId, category }) =>
                                <Box key={_id}>
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar alt={category.charAt(0)}
                                                src={`${GOOGLE_DRIVE_CDN}${imgId}`} />
                                        </ListItemAvatar>
                                        <ListItemText primary={category} />
                                    </ListItemButton>
                                </Box>
                            )
                        }
                    </Box>
                    <Button variant="contained"
                        onClick={deleteMany} color="error">
                        Eliminar categorías seleccionadas
                    </Button>
                </Box>
            }

            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={() => { setOpenAlert(false) }}
                message="Note archived"
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={() => { setOpenAlert(false) }}>
                            UNDO
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => { setOpenAlert(false) }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

            <DragDropContext
                onDragEnd={(result) => onDragEnd(result)}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <Grid container spacing={4}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {
                                allCategories.map((category, index) => (
                                    <ProductCategoryList
                                        key={category._id}
                                        _category={category}
                                        categories={allCategories}
                                        setCategories={setCategories}
                                        setDeleted={setDeleted}
                                        index={index}
                                    />
                                ))
                            }
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default ProductCategories;