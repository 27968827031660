import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { simpleConfirmDialog } from '../../../../actions/swal_msg';
import { deleteBrands } from '../../../../actions/admin/product/product_brand';

import {
    Card, CardMedia, CardActions, CardContent, Grid,
    Typography, Checkbox,
    CardHeader
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

const BrandCard = ({ brand: { _id, brand, imgId, checked }, setBrands, index }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const deleteBrand = async () => {
        if (await simpleConfirmDialog('¿Eliminar marca de producto?', 'Sí, continuar')) {
            setLoading(true);
            dispatch(deleteBrands([{ _id, brand, imgId }], setLoading));
        }
    };

    const handleChange = () => {
        setBrands(e => e.map((u, i) => i === index ? { ...u, checked: !u.checked } : u));
    };

    return (
        <Draggable key={_id} draggableId={_id} index={index}>
            {(provided, snapshot) => (
                <Grid className='brands_grid' item xxl={3} xl={3} lg={4} md={6} sm={6} xs={12}>
                    <Card
                        sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;', borderRadius: '6px' }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        {/* <CardMedia
                            sx={{ height: 140, backgroundSize: 'contain' }}
                            image={`${GOOGLE_DRIVE_CDN}${imgId}`}
                            title={ brand }
                        /> */}
                        <CardHeader
                            action={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                />
                            }

                            subheader={brand}
                        />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <img src={`${GOOGLE_DRIVE_CDN}${imgId}`} alt={brand} width='80%' />
                            {/* <Typography gutterBottom variant="h5" component="div">
                                {brand}
                            </Typography> */}
                        </CardContent>
                        <CardActions>
                            {/* <Checkbox
                                checked={checked}
                                onChange={handleChange}
                            /> */}
                            <LoadingButton size='small'
                                endIcon={<DeleteForeverIcon />}
                                loading={loading}
                                loadingPosition="end"
                                color="error"
                                variant="contained"
                                onClick={deleteBrand}>
                                Eliminar
                            </LoadingButton>
                        </CardActions>
                    </Card>
                </Grid>
            )}
        </Draggable>
    );
};

export default BrandCard;