import React, { useState } from 'react';

//Router dom
import { Link } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//CSS
import '../../Admin/Header/adminHeader.css';

//Actions
import { costOfSaleInfo } from '../../../actions/admin';
import { logout } from '../../../actions/user';

//XLSX
import XLSX from 'sheetjs-style';

//File saver
import * as FileSaver from 'file-saver';

//Date fns
import { format } from 'date-fns';

//MUI
import {
    Typography, ListItemButton, Backdrop, Stack, Collapse, List, Menu, Avatar, Divider, MenuItem, IconButton, ListItemIcon, ListItemText
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Drawer list data
import { data } from './DrawerListData';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const DrawerList = (props) => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector(state => state.user);
    const { username } = loggedUser;

    // cerrar sesión
    const logout_fn = () => {
        dispatch(logout());
    };

    const [open, setOpen] = useState(false);

    //Menu avatar
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // generar reporte de Webinar NIN
    const webinarReport = async () => {
        window.location.href = 'https://www.nefropolis.com/reporte_webinar/report.php';
    };

    // generar reporte de costo de venta
    const costOfSale = async () => {
        setOpen(true);
        const req = await costOfSaleInfo();
        if (req.status)
            exportToExcel(req.resp);
        setOpen(false);
    };

    const exportToExcel = async (array) => {
        const ws = XLSX.utils.json_to_sheet(array);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
            data,
            `costo_ventas${format(new Date(), '_dd_MM_yyyy')
            }${fileExtension}`
        );
    };

    const [expanded, setExpanded] = useState(false);

    const handleOpen = (index) => {
        if (expanded === index) {
            setExpanded(false);
        } else {
            setExpanded(index);
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}>
                <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                    <Typography variant='body1'>Generando reporte...</Typography>
                    <CircularProgress color="inherit" fontSize="small" />
                </Stack>
            </Backdrop>
            <div className='img-admin-sidebar-header bg-wrap'>
                <div className="user-logo">
                    <Avatar
                        sx={{ backgroundColor: '#2699FB', fontWeight: 'bold' }}
                        className='img-user-logo'>
                        {username.charAt(0)}
                    </Avatar>
                    <div>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Typography variant='body1'
                                sx={{ fontWeight: '600', color: '#ffff' }}>
                                {username} <ArrowDropDownIcon />
                            </Typography>
                        </IconButton>
                        <Typography variant="caption" display="block" gutterBottom
                            sx={{ color: '#ffff' }}>
                            Versión 3.0
                        </Typography>
                        <Menu
                            sx={{ mt: '25px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={logout_fn}><LogoutIcon sx={{ mr: 2 }} />
                                Cerrar sesión
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>

            <List disablePadding>
                {
                    data.map((res, index) => (
                        <>
                            <ListItemButton onClick={() => handleOpen(index)}
                                sx={[
                                    {
                                        bgcolor: '#F2F3F4',
                                        borderBottom: '1px solid #F2F3F4'
                                    },
                                    {
                                        '&:hover': {
                                            backgroundColor: '#EAEDED'
                                        }
                                    }
                                ]}
                            >
                                <ListItemIcon>
                                    {res.icon}
                                </ListItemIcon>
                                <ListItemText primary={res.name} />
                                {expanded === index ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        res.submenu.map(({ icon, title, url }) => {
                                            const Icon = icon;
                                            return url === 'reporte-webinar' ?
                                                <ListItemButton key={url}
                                                    sx={{ pl: 4 }}
                                                    onClick={webinarReport}>
                                                    <ListItemIcon>
                                                        <Icon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={title} />
                                                </ListItemButton>
                                                :
                                                url === 'costo-ventas' ?
                                                    <React.Fragment key={url}>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                            onClick={costOfSale}>
                                                            <ListItemIcon>
                                                                <Icon />
                                                            </ListItemIcon>
                                                            <ListItemText primary={title} />
                                                        </ListItemButton>
                                                        <Divider />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment key={url}>
                                                        <ListItemButton
                                                            sx={{ pl: 4 }}
                                                            onClick={props.open}
                                                            component={Link}
                                                            to={"/nefro-admin/" + url}>
                                                            <ListItemIcon>
                                                                <Icon />
                                                            </ListItemIcon>
                                                            <ListItemText primary={title} />
                                                        </ListItemButton>
                                                        <Divider />
                                                    </React.Fragment>

                                        })
                                    }
                                </List>
                            </Collapse>
                        </>
                    ))
                }
            </List>
        </>
    );
};

export default DrawerList;