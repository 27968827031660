import { types } from "../types/types";

// toggle inserción productos
export const setCategoryToggle = (pos) => ({
    type: types.ui_categoryToggle,
    payload: pos
});

// abrir/cerrar modal AdminUserModal
export const adminUserModalToggle = (payload) => ({
    type: types.ui_adminUserModal,
    payload
});

// abrir/cerrar modal FormModal
export const formsModalToggle = (payload) => ({
    type: types.ui_formsModal,
    payload
});

// abrir/cerrar modal CommentModal
export const commentModalToggle = (payload) => ({
    type: types.ui_commentModal,
    payload
});

// abrir/cerrar modal LoginComponent
export const loginModalToggle = (payload) => ({
    type: types.ui_loginModal,
    payload
});

// abrir/cerrar dialog AdminsUserList
export const adminUserDialogToggle = (payload) => ({
    type: types.ui_adminUserDialog,
    payload
});

// muestra/oculta snackbar en caso de error en petición
export const shopSnackbarToggle = (payload) => ({
    type: types.ui_shopSnackbar,
    payload
});

// registro blog / actualización blog / registro producto (admin)
export const uiLoading2 = (payload) => ({
    type: types.uiLoading2,
    payload
});

// usuario sin contraseña trató de iniciar (se envió código de 6 dígitos => abrir modal)
export const loginCodeModal = (payload) => ({
    type: types.uiLoginCodeModal, payload
});

// cambiar de pestaña en lista de pedidos de usuario (mi-cuenta/mis-pedidos)
// Mis pedidos / Comprados recientemente / Pedidos abandonados
export const uiUserOrdersTab = (payload) => ({
    type: types.userOrdersTab, payload
});

// carga de datos para módulo de admin (status)
export const adminModuleData = (payload) => ({
    type: types.uiAdminModuleData, payload
});
// carga de productos de tienda (status)
export const shopProductData = (payload) => ({
    type: types.uiShopProductData, payload
});

// producto agregado a carrito => mostrar diálogo y mensajes
export const shopProductModal = (payload) => ({
    type: types.uiShopProductModal, payload
});