import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { NavContext } from '../../../../context/NavContext';
import { getTitle } from '../../../../helpers/admin/productHelper';
import { saveItemToCart } from '../../../../actions/shop';

//MUI
import {
    Alert, Button, Card, CardActions, CardContent, CardMedia,
    Grid, Typography, Stack
} from '@mui/material';
import { styled } from "@mui/material/styles";

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../../constants/constants';

const ButtonGreen = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    fontWeight: '600',
    paddingTop: '8px',
    paddingBottom: '8px',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

const BuyAgain = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setValue } = useContext(NavContext);

    // productos de tienda y datos de navegación
    // cambió
    const { action, products } = useSelector(state => state.shop);

    const { buyAgainProducts } = useSelector(state => state.user_account);
    const [item, setItem] = useState([]);
    
    useEffect(() => {
        // cliente tiene compras
        if (buyAgainProducts.length > 0) {
            // productos en tienda que cliente ya ha comprado
            const filtered = products.filter(
                e => e.prod_variants.filter(
                    ({ _id }) => buyAgainProducts.map(
                        ({ prod_var_id }) => prod_var_id
                    ).includes(_id)
                ).length > 0
            ).map(
                ({ prod_variants }) => prod_variants.map(
                    ({ _id, item_promotion }) => ({ _id, item_promotion })
                )
            );

            if (filtered.length > 0) {
                let MyProducts = [];
                // incluir promoción de producto (en caso de)
                // para mostrar el precio correcto
                for (const item of buyAgainProducts) {
                    const { prod_var_id } = item;
                    for (const product of filtered) {
                        for (const variant of product) {
                            const { _id, item_promotion } = variant;
                            if (_id === prod_var_id)
                                MyProducts = [...MyProducts, {
                                    ...item, item_promotion
                                }];
                        };
                    };
                };
                setItem(MyProducts);
            }
        }
    }, [buyAgainProducts, products]);

    // producto agregado a carrito
    useEffect(() => {
        if (action !== '') {
            let text = '¿Desear proceder al carrito?';
            if (action === 'exceeds-stock')
                text = 'Hicimos ajustes en su carrito dado que supera existencias en producto. ¿Desear proceder al carrito?';
            else if (action === 'promo-limit-2x1')
                text = 'Debido a promoción 2x1, mantiene sólo 2 unidades en carrito. ¿Desear proceder al carrito?';
            else if (action === 'promo-limit-4x3')
                text = 'Debido a promoción 4x3, mantiene sólo 4 unidades en carrito. ¿Desear proceder al carrito?';
            Swal.fire({
                title: 'Producto agregado',
                text,
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Mi carrito',
                cancelButtonText: 'Seguir comprando',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/carrito');
                    setValue('/carrito');
                }
            });
        }
    }, [action]);
    
    // agregar producto a carrito
    const addToCart = model => () => {
        dispatch(saveItemToCart({
            ...model, quantity: 1
        }, { flag: false, message: [] }, () => {}));
        // Tag Manager
        window.dataLayer.push({
            event: 'add-to-cart'
        });
    };
    
    return (
        <>
            {
                item.length > 0 ?
                    <Grid container spacing={4}>
                        {
                            item.map(({
                                prod_id, prod_var_id, title, variant_title, price,
                                item_promotion, images
                            }) => (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}
                                    key={ prod_var_id } sx={{ display: 'flex' }}>
                                    <Card sx={{ width: '100%', position: 'relative', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 160, height: 160, objectFit: 'cover', mx: 'auto', mt: 3 }}
                                            image={
                                                    `${GOOGLE_DRIVE_CDN}${
                                                        images.length > 0 ?
                                                            images[0].id
                                                        :
                                                            PRODUCT_NO_IMG
                                                    }`
                                            }
                                            title={ getTitle(title, variant_title) }
                                        />
                                        <CardContent sx={{ mb: 6 }}>
                                            <Typography variant='body2' gutterBottom>
                                                { getTitle(title, variant_title) }
                                            </Typography>
                                            {
                                                item_promotion &&
                                                    ['Descuento$Producto', 'Descuento%Producto'].includes(
                                                        item_promotion.promo
                                                    ) ?
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="baseline"
                                                        spacing={1}>
                                                        <Typography variant="body1"
                                                            sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                            ${price}
                                                        </Typography>
                                                        <Typography variant="body1"
                                                            sx={{ fontWeight: '600', color: '#F90606' }}>
                                                            ${item_promotion.price}
                                                        </Typography>
                                                    </Stack>
                                                :
                                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                                        ${price}
                                                    </Typography>
                                            }
                                        </CardContent>
                                        <CardActions sx={{ position: 'absolute', bottom: 0, width: '100%', px: 2 }}>
                                            <ButtonGreen size="small" variant='contained'
                                                sx={{ width: '100%', textTransform: 'none' }}
                                                onClick={
                                                    addToCart({ prod_id, prod_var_id })
                                                }>
                                                Agregar al carrito
                                            </ButtonGreen>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                :
                    <Alert severity="warning">Aún no cuentas con ninguna compra - <Link to='/tienda'>Comenzar a comprar</Link></Alert>
            }
        </>
    );
};

export default BuyAgain;