import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BrandDialog from './BrandDialog';
import BrandDragDrop from './BrandDragDrop';

import { categoriesLoad } from '../../../../actions/category';
import { simpleConfirmDialog } from '../../../../actions/swal_msg';
import { deleteBrands, updateBrands } from '../../../../actions/admin/product/product_brand';

import {
    Button, Typography, Alert, Fab, Container, Box
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const BrandContainer = () => {
    const dispatch = useDispatch();

    // marcas de producto
    const { brands } = useSelector(state => state.category);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [changed, setChanged] = useState(false);

    const [allBrands, setBrands] = useState([]);
    // const [brandsBackup, setBrandsBackup] = useState([]);

    useEffect(() => {
        dispatch(categoriesLoad()); // categorías y marcas de producto
    }, []);

    useEffect(() => {
        setBrands(brands.map(e => ({ ...e, checked: false })));
        // setBrandsBackup(brands.map(e => ({ ...e, checked: false })));
    }, [brands]);

    // useEffect(() => {
    //     if (JSON.stringify(brandsBackup) === JSON.stringify(allBrands))
    //         setChanged(false);
    //     else setChanged(true);
    // }, [allBrands]);

    const deleteMultipleBrands = async () => {
        if (await simpleConfirmDialog('¿Eliminar marcas seleccionadas?', 'Sí, continuar')) {
            setLoading(true);
            dispatch(deleteBrands(allBrands.filter(e => e.checked), setLoading));
        }
    };

    const updateBrandsFn = () => {
        setLoading(true);
        dispatch(updateBrands(allBrands, setLoading));
    };

    return (
        <Container maxWidth='xxl' sx={{ mb: 5, mt: 12 }}>

            <Box
                mb={3}
                sx={{
                    display: 'flex',
                    justifyContent: "flex-start",
                    flexWrap: 'wrap',
                    gap: '20px',
                    alignItems: "flex-start"
                }}>
                <Typography variant="h5" gutterBottom sx={{ my: 'auto' }}>
                    Marcas de producto
                </Typography>
                <Button variant="outlined" sx={{ textTransform: 'none', my: 'auto' }}
                    endIcon={
                        <AddIcon fontSize="inherit" />
                    } onClick={() => setOpen(true)}>
                    Agregar
                </Button>
            </Box>

            {
                allBrands.filter(e => e.checked).length > 0 &&
                <LoadingButton
                    sx={{ mb: 3 }}
                    endIcon={<DeleteForeverIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    onClick={deleteMultipleBrands}>
                    Eliminar marcas seleccionadas
                </LoadingButton>
            }

            {/* {allBrands.filter(e => e.checked).map((res) => res(
                <>
                    {res}
                </>
            ))} */}

            {
                brands.length > 0 ?
                    <BrandDragDrop
                        brands={allBrands}
                        setBrands={setBrands}
                    />
                    :
                    <Alert severity="info">No hay marcas registradas.</Alert>
            }

            <BrandDialog
                open={open}
                setOpen={setOpen}
            />
            {/* {
                changed &&
                    <Fab color="primary"
                        aria-label="add" sx={{
                            position: "fixed",
                            bottom: (theme) => theme.spacing(2),
                            right: (theme) => theme.spacing(2)
                        }}
                        disabled={ loading }
                        onClick={ updateBrandsFn }>
                        <SaveIcon />
                    </Fab>
            } */}
            <Fab color="primary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                disabled={loading}
                onClick={updateBrandsFn}>
                <UpdateIcon />
            </Fab>
        </Container>
    );
};

export default BrandContainer;