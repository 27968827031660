import React from 'react'

//Owl carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Images
import iztapalapa from "../../../assets/resources/sucursales/full-sucursal-1.png"
import roma from "../../../assets/resources/sucursales/roma_2.jpg"
import tlane from "../../../assets/resources/sucursales/tlalnepantla_2.jpg"
import tlalpan from "../../../assets/resources/sucursales/tlalpan_1.jpg"
import valle from "../../../assets/resources/sucursales/valle-de-aragon.jpg"
// import EnviosCDMX from '../../../assets/resources/sucursales/EnviosCDMX.jpg'
// import promocion_1 from '../../../assets/resources/sucursales/alimentos_nefropolis.jpg'
// import promocion_2 from '../../../assets/resources/sucursales/nefrolealtad.png'
// import promocion_3 from '../../../assets/resources/sucursales/Recurso-3.png'
// import promocion_4 from '../../../assets/resources/sucursales/promocional.png'
// import promocion_5 from '../../../assets/resources/sucursales/Promo_Nepro-HP.jpeg'
// import promocion_6 from '../../../assets/resources/sucursales/Todos-los-dias-Nefrolealtad.png'

//MUI
import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';

const SucursalesCdmxComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    const options = {
        margin: 10,
        nav: false,
        dots: true,
        mouseDrag: false,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            800: {
                items: 3,
            },
            1000: {
                items: 3,

            }
        },
    };

    return (
        <>
            {/* Sucursales CDMX header */}
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant="h4" align='center' sx={{ fontWeight: '600' }} gutterBottom>Ciudad de México</Typography>
                <Typography variant='h5' align='center'>
                    SOMOS UNA CADENA DE TIENDAS ESPECIALIZADAS EN LA VENTA DE ALIMENTOS Y SUPLEMENTOS PARA PERSONAS CON ENFERMEDAD RENAL.
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 3 }}>
                    <Typography variant='body1' sx={{ color: '#00a7e2', fontWeight: 'bold' }}>
                        ¡Visita tu sucursal más cercana!
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#00a7e2', fontWeight: 'bold' }}>
                        CONTAMOS CON MÁS DE 80 PRODUCTOS
                    </Typography>
                </Box>
            </Container>
            {/* Fin Sucursales CDMX header */}

            {/* sucursal Iztalapalapa */}
            <Container maxWidth="xl" sx={{ mt: 6 }}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'light', textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' } }}
                    gutterBottom
                >
                    Sucursal Iztapalapa
                </Typography>

                <img src={iztapalapa} width="100%" alt="" />

                {/* Información de sucursal */}

                <OwlCarousel className='owl-theme' {...options} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Dirección</Typography>
                        <Typography variant="body1" gutterBottom>
                            Av. Tlahuac 19, Santa Isabel Industrial, Iztapalapa, 09820 Ciudad de México, CDMX
                        </Typography>
                        <a href='https://www.google.com/maps/place/Nefr%C3%B3polis+Sucursal+Iztapalapa/@19.3552594,-99.1081844,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff5e7ba75905:0x500daaa89ae32103!8m2!3d19.3552594!4d-99.1059957' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant='contained'>Ver en mapa</Button>
                        </a>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-address-book" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Contacto</Typography>
                        <Typography variant="body1">
                            Teléfono: 55 9046 2288 <br /> WhatsApp: 33 2339 2969 <br /> iztapalapa@nefropolis.com
                        </Typography>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-clock" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Horario</Typography>
                        <Typography variant="body1">
                            Lunes a Viernes <br /> 9:30 am - 2 pm, 2:30 pm - 6 pm <br /> Sábado <br /> 10:00 am – 2:30 pm
                        </Typography>
                    </div>
                </OwlCarousel>
                {/* Fin Información de sucursal */}
            </Container>
            {/* Fin sucursal Iztalapalapa */}

            {/* sucursal Roma ----------------------------------------------------------------- */}
            <Container maxWidth="xl" sx={{ mt: 6 }}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'light', textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' } }}
                    gutterBottom
                >
                    Sucursal Roma
                </Typography>

                <img src={roma} width="100%" alt="" />

                {/* Información de sucursal */}

                <OwlCarousel className='owl-theme' {...options} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Dirección</Typography>
                        <Typography variant="body1" gutterBottom>
                            C. Manzanillo 197, Roma Sur, Cuauhtémoc, 06760 Ciudad de México, CDMX.
                        </Typography>
                        <a href='https://www.google.com/maps/place/Nefr%C3%B3polis+Sucursal+Roma+Sur/@19.4021906,-99.1640641,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1ff1389ec97b7:0x95b5e3c41b77416e!8m2!3d19.4021906!4d-99.1640641' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant='contained'>Ver en mapa</Button>
                        </a>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-address-book" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Contacto</Typography>
                        <Typography variant="body1">
                            Teléfono: 55 6387 9987 <br /> WhatsApp: 33 2339 3005 <br /> roma@nefropolis.com
                        </Typography>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-clock" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Horario</Typography>
                        <Typography variant="body1">
                            Lunes a Viernes <br /> 9:30 am - 2 pm, 2:30 pm - 6 pm <br /> Sábado <br /> 10:00 am – 2:30 pm
                        </Typography>
                    </div>
                </OwlCarousel>
                {/* Fin Información de sucursal */}
            </Container>
            {/* Fin sucursal Roma ----------------------------------------------------------------- */}

            {/* sucursal Tlalnepantla ----------------------------------------------------------------- */}
            <Container maxWidth="xl" sx={{ mt: 6 }}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'light', textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' } }}
                    gutterBottom
                >
                    Sucursal Tlalnepantla
                </Typography>

                <img src={tlane} width="100%" alt="" />

                {/* Información de sucursal */}

                <OwlCarousel className='owl-theme' {...options} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Dirección</Typography>
                        <Typography variant="body1" gutterBottom>
                            Av. Gustavo Baz #3315 Local C-17, Plaza Olímpus, 54000 Ciudad de México, Méx.
                        </Typography>
                        <a href='https://www.google.com/maps/place/Nefr%C3%B3polis+Sucursal+Tlalnepantla/@19.5408736,-99.2075803,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1f03c6e651689:0x2c04a0a7955a455c!8m2!3d19.5408721!4d-99.2075323' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant='contained'>Ver en mapa</Button>
                        </a>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-address-book" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Contacto</Typography>
                        <Typography variant="body1">
                            Teléfono: 55 7601 1938 <br /> WhatsApp: 33 2339 2972 <br /> tlalnepantla@nefropolis.com
                        </Typography>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-clock" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Horario</Typography>
                        <Typography variant="body1">
                            Lunes a Viernes <br /> 9:30 am - 2 pm, 2:30 pm - 6 pm <br /> Sábado <br /> 10:00 am – 2:30 pm
                        </Typography>
                    </div>
                </OwlCarousel>
                {/* Fin Información de sucursal */}
            </Container>
            {/* Fin sucursal Tlalnepantla ----------------------------------------------------------------- */}

            {/* sucursal Tlalpan ----------------------------------------------------------------- */}
            <Container maxWidth="xl" sx={{ mt: 6 }}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'light', textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' } }}
                    gutterBottom
                >
                    Sucursal Tlalpan
                </Typography>

                <img src={tlalpan} width="100%" alt="" />

                {/* Información de sucursal */}

                <OwlCarousel className='owl-theme' {...options} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Dirección</Typography>
                        <Typography variant="body1" gutterBottom>
                            Periferico Sur #5580-P.B, El Caracol, Coyoacán, 04739 Ciudad de México, CDMX.
                        </Typography>
                        <a href='https://www.google.com/maps/place/Nefr%C3%B3polis+Sucursal+Tlalpan/@19.3017663,-99.1630814,17z/data=!3m1!4b1!4m5!3m4!1s0x85ce0137db036617:0x30970f26baa8ba9b!8m2!3d19.3017663!4d-99.1630814' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant='contained'>Ver en mapa</Button>
                        </a>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-address-book" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Contacto</Typography>
                        <Typography variant="body1">
                            Teléfono: 55 8879 4596 <br /> WhatsApp: 33 2312 7853 <br /> tlalpan@nefropolis.com
                        </Typography>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-clock" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Horario</Typography>
                        <Typography variant="body1">
                            Lunes a Viernes <br /> 9:30 am - 2 pm, 2:30 pm - 6 pm <br /> Sábado <br /> 10:00 am – 2:30 pm
                        </Typography>
                    </div>
                </OwlCarousel>
                {/* Fin Información de sucursal */}
            </Container>
            {/* Fin sucursal Tlalpan ----------------------------------------------------------------- */}

            {/* sucursal Valle de Aragón ----------------------------------------------------------------- */}
            <Container maxWidth="xl" sx={{ mt: 6 }}>
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'light', textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' } }}
                    gutterBottom
                >
                    Sucursal Valle de Aragón
                </Typography>

                <img src={valle} width="100%" alt="" />

                {/* Información de sucursal */}

                <OwlCarousel className='owl-theme' {...options} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Dirección</Typography>
                        <Typography variant="body1" gutterBottom>
                            Av. Central 854, Col Valle de Aragon 1era sección, 57100 Nezahualcóyotl, Méx.
                        </Typography>
                        <a href='https://www.google.com/maps/place/Nefropolis+Sucursal+Valle+de+Arag%C3%B3n/@19.4901933,-99.0479787,17z/data=!3m1!4b1!4m5!3m4!1s0x85d1fb503f48e597:0x28a8810cab3b408!8m2!3d19.4901781!4d-99.0479404' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant='contained'>Ver en mapa</Button>
                        </a>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-address-book" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Contacto</Typography>
                        <Typography variant="body1">
                            Teléfono: 55 8948 9747 <br /> WhatsApp: 33 3559 1185 <br /> valledeearagon@nefropolis.com
                        </Typography>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-clock" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Horario</Typography>
                        <Typography variant="body1">
                            Lunes a Viernes <br /> 9:30 am - 2 pm, 2:30 pm - 6 pm <br /> Sábado <br /> 10:00 am – 2:30 pm
                        </Typography>
                    </div>
                </OwlCarousel>
                {/* Fin Información de sucursal */}
            </Container>
            {/* Fin sucursal Valle de Aragón ----------------------------------------------------------------- */}

            {/* Promociones */}
            {/* <Container maxWidth="xl" sx={{ mt: 6 }}>
                <Typography variant="h4" sx={{ fontWeight: '600' }} align='center'>Promociones en sucursal</Typography>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={EnviosCDMX} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_1} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_2} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_3} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_4} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_5} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_6} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                </Grid>
            </Container> */}
            {/* Fin Promociones */}

            {/* Map */}
            <Container maxWidth="xl" sx={{ mt: 6 }} className="last-view">
                <iframe title='CDMX' src="https://www.google.com/maps/d/embed?mid=1fJvpKq7BDcEZZyXy8_iL96Yr7JwKZDdw&ehbc=2E312F"
                    width="100%" height="480" className='border-0'></iframe>
            </Container>
            {/* Fin Map */}
        </>
    )
}

export default SucursalesCdmxComponent