import React from 'react';

import {
    Box, Card, CardContent,Typography, Stack, List, ListItem
} from '@mui/material';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

const CouponSummary = ({ coupon }) => {
    const {
        _class, name, description, use_method, discount,
        available, restrictions, purchase_requirements
    } = coupon;
    const { type, _value } = discount;
    const { one_per_user, limited, quantity } = restrictions;
    const {
        active, min_purchase_amount, minimum_items_qty,
        applies_to
    } = purchase_requirements;
    const { amount } = min_purchase_amount;
    const { items_qty } = minimum_items_qty;
    const { category, product } = applies_to;

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    Resumen
                </Typography>

                {
                    name !== '' &&
                        <Stack direction="row" spacing={1}>
                            <Typography variant="body1"
                                color="text.secondary"
                                gutterBottom
                                
                            >
                                Código de descuento:
                            </Typography>
                            <Typography variant="body1"
                                color="text.secondary"
                                gutterBottom
                                sx={{ fontWeight: 'Bold' }}
                            >
                                { name }
                            </Typography>
                        </Stack>
                }
                
                {
                    name !== '' &&
                        <Stack direction="row" spacing={1}>
                            <Typography variant="body1"
                                color="text.secondary"
                                gutterBottom
                                
                            >
                                Mensaje al ser aplicado:
                            </Typography>
                            <Typography variant="body1"
                                color="text.secondary"
                                gutterBottom
                                sx={{ fontStyle: 'italic' }}
                            >
                                { description }
                            </Typography>
                        </Stack>
                }
                <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                        {
                            _class === 'order_discount' ?
                                _value !== '' ?
                                    type === 'percentage' ?
                                        `Descuento de ${_value} % en total de un pedido (antes de envío)`
                                    :
                                        `Descuento de $${_value} en total de un pedido (antes de envío)`
                                :
                                    `Descuento en total de un pedido (antes de envío)`
                            :
                                `Pedido tiene envío gratis`
                        }
                    </ListItem>

                    {/* <ListItem disablePadding>
                        <ListItemIcon>
                            <HorizontalRuleOutlined />
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography variant="body2"
                                color="text.secondary"
                                gutterBottom
                            >
                                {
                                    _class === 'order_discount' ?
                                        _value !== '' ?
                                            type === 'percentage' ?
                                                `Descuento de ${_value} % en cantidad total de un pedido (antes de envío)`
                                            :
                                                `Descuento de $${_value} en cantidad total de un pedido (antes de envío)`
                                        :
                                            `Descuento en cantidad total de un pedido (antes de envío)`
                                    :
                                        `Pedido tiene envío gratis`
                                }
                            </Typography>
                        } />
                    </ListItem> */}
                    
                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                        {
                            use_method === 'by_code' ?
                                'Descuento es aplicado mediante cupón'
                            :
                                `Descuento es aplicado automáticamente al cumplir los requisitos de compra`
                        }
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                        {
                            limited ?
                                    one_per_user ?
                                        `Cupón está limitado a ${quantity === '' ? 'X' : quantity} usos, uno por usuario`
                                    :
                                        `Cupón está limitado a ${quantity === '' ? 'X' : quantity} usos, sin límite por usuario`
                            :
                                one_per_user ?
                                    `Cupón está limitado a un uso por usuario`
                                :
                                    'Cupón permite más de un uso por usuario'
                        }
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }} disablePadding>
                        {
                            available ?
                                'Cupón disponible en tienda'
                            :
                                'Cupón está deshabilitado y no puede aplicarse en tienda'
                        }
                    </ListItem>
                </List>

                {
                    active &&
                        <>
                            <Typography variant="subtitle1"
                                sx={{ fontWeight: 'Bold', mt: 2 }}>
                                Requisitos de compra
                            </Typography>

                            {
                                (
                                    !min_purchase_amount.active &&
                                    !minimum_items_qty.active &&
                                    !category.active && !product.active
                                ) &&
                                <Typography variant="body1"
                                    sx={{ fontStyle: 'italic' }}>
                                    Establecer por lo menos un requisito
                                </Typography>
                            }
                            {
                                min_purchase_amount.active &&
                                    <Typography variant="body1">
                                        { bull }
                                        {
                                            `Total de carrito debe ser de $${
                                                amount === '' ? 'X' : amount
                                            } o más (antes de envío)`
                                        }
                                    </Typography>
                            }
                            {
                                minimum_items_qty.active &&
                                    <Typography variant="body1">
                                        { bull }
                                        {
                                            `Carrito debe tener por lo menos ${
                                                items_qty === '' ? 'X' : items_qty
                                            } producto${
                                                Number(items_qty) > 1 ? 's' : ''
                                            }`
                                        }
                                    </Typography>
                            }
                            {
                                product.active &&
                                    <>
                                        <Typography variant="body1">
                                            { bull }
                                            {
                                                `Carrito debe tener los ${
                                                    product.list.length > 0 ? 'siguientes productos:'
                                                    : 'productos que se indiquen'
                                                }`
                                            }
                                        </Typography>
                                        {
                                            product.list.length > 0 &&
                                                <Typography variant="button" display="block">
                                                    {
                                                        JSON.stringify(product.list.map(
                                                            e => e.full_title
                                                        )).replace(/["\[\]]/g, '').replace(/,/g, ' - ')
                                                    }
                                                </Typography>
                                        }
                                    </>
                            }
                        </>
                }

                <Typography variant="subtitle1"
                    sx={{ fontWeight: 'Bold', mt: 2 }}>
                    Compatibilidad
                </Typography>
                <Typography variant="body1">
                    Cupón es compatible con promociones de producto
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CouponSummary;