import React from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

//Owl carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { variantDelete, variantEdit } from '../../../../../actions/product';

import { GOOGLE_DRIVE_CDN } from '../../../../../constants/constants';

import {
  IconButton, AccordionActions, Button, Stack,
  Accordion, AccordionSummary, AccordionDetails,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const VariantItem = ({ pos, product, variant, setOpenDrawer }) => {
  const dispatch = useDispatch();
  const { prod_id } = product;
  const {
    _id, variant_title, price,
    stock, barcode, folderId, images
  } = variant;

  const editVariant = () => {
    dispatch(variantEdit({ ...product, ...variant }));
    setOpenDrawer(false)
  };

  const deleteVariant = () => {
    Swal.fire({
      title: `¿Desea eliminar la variante de producto?`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(variantDelete({ prod_id, _id, folderId }));
      }
    });
  };

  const options = {
    margin: 10,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,

      }
    },
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel-variant-item">
        <Stack sx={{ width: '100%' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography>
            {
              variant_title !== ''
                ? variant_title
                : `Variante ${pos}`
            }
          </Typography>
          <Typography variant="subtitle1"
            component="div"
            sx={{ fontWeight: 'bold' }}>
            ${price}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <OwlCarousel className="owl-theme" {...options} style={{ marginBottom: '20px' }}>
          {
            images.map(item => {
              return (
                <div className="item"
                  style={{ textAlign: 'center' }}
                  key={item.id}>
                  <img src={`${GOOGLE_DRIVE_CDN}${item.id}`}
                    alt={item.alt} width="150" height="150" style={{ objectFit: 'contain' }}
                    loading="lazy" />
                </div>
              )
            })
          }
        </OwlCarousel>
        <Typography variant="overline" display="block">
          ({stock} disponibles)
        </Typography>
        {
          barcode !== '' &&
          <Typography variant="body2">
            Código de barras: {barcode}
          </Typography>
        }
      </AccordionDetails>
      <AccordionActions sx={{ justifyContent: 'flex-start' }}>
        <Button variant="text" onClick={editVariant}>Editar</Button>
        <IconButton aria-label="delete"
          onClick={deleteVariant}>
          <DeleteIcon />
        </IconButton>
      </AccordionActions>
    </Accordion>
  );
};

export default VariantItem;