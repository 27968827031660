import { types } from '../types/types';

import { basicMsg, msg } from './swal_msg';
import { nefroRequest, request } from "../helpers/requestHelper";

// obtiene municipio, estado, y colonias en base al código postal
export const getPostalcodeData = async(cp) => {
    try {
        const form = new FormData();
        form.append('id', cp);
        const resp = await nefroRequest('CP/', form, 'POST');
        if (Array.isArray(resp.data.data)) {
            return {
                status: true,
                resp: {
                    ...resp.data.data[0],
                    ...resp.data.data[1]
                }
            };
        } else return { status: false };
    } catch (err) {
        return { status: false };
    };
};

// obtiene régimen fiscal
export const getTaxSystemData = () => {
    return async(dispatch) => {
        try {
            const resp = await nefroRequest('regimen/');
            if (Array.isArray(resp.data.data))
                dispatch(taxSystemDataReceived(resp.data.data));
        } catch (err) {};
    }
};
const taxSystemDataReceived = (payload) => ({
    type: types.checkoutTaxSystem,
    payload
});

// obtiene CFDI
export const getCFDIData = () => {
    return async(dispatch) => {
        try {
            const resp = await nefroRequest('usoCFDI/');
            if (Array.isArray(resp.data.data))
                dispatch(CFDIDataReceived(resp.data.data));
        } catch (err) {};
    }
};
const CFDIDataReceived = (payload) => ({
    type: types.checkoutCFDI,
    payload
});

export const checkOrderCoupon = async(model) => {
    try {
        // token carrito
        const cart_token = localStorage.getItem('cart-token') || '';
        
        const resp = await request('shop/check-coupon',
            { cart_token, ...model }, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg !== 'miss') {
                localStorage.removeItem('coupon');
                if (body.msg === 'already-used')
                    msg('info', 'Checkout',
                    'Ya ha utilizado este cupón en un pedido anterior');
                else if (body.msg === 'disabled')
                    msg('info', 'Checkout',
                    'El cupón no está disponible');
                else if (body.msg === 'out-of-date')
                    msg('info', 'Checkout',
                    'El cupón ha expirado');
                else if (body.msg === 'not-available')
                    msg('info', 'Checkout',
                    'El cupón ya no está disponible');
                else
                    msg('info', 'Checkout',
                    'No se ha encontrado el cupón');
            } else {
                basicMsg('Complete información de pedido');
                return {
                    status: false,
                    resp: { cart_msg: [] }
                }
            }
        }
        return body;
    } catch (err) {
        return { status: false };
    }
};

export const saveClientEmail = async(email) => {
    try {
        // token carrito
        const cart_token = localStorage.getItem('cart-token') || '';
        const resp = await request('checkout/save-email', { email, cart_token }, 'POST');
        await resp.json();
    } catch (err) {};
};

// guardar información de cliente en carrito
/* en caso de que no finalice la compra,
   poder enviar correo de carrito abandonado */
export const saveClientData = async(shipping_data) => {
    try {
        const {
            name, lastname, email, tel, street, ext_num, int_num,
            postalcode, colony, city, state
        } = shipping_data;
        // token carrito
        const cart_token = localStorage.getItem('cart-token') || '';
        const model = {
            fullname: `${name} ${lastname}`.trim(),
            email, tel, postalcode, colony, city, state,
            address: `${street} ${ext_num} ${int_num}`.trim(),
            cart_token
        };
        const resp = await request('checkout/save-data', model, 'POST');
        await resp.json();
    } catch (err) {};
};