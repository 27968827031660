//MUI icons
import BookIcon from '@mui/icons-material/Book';
import StoreIcon from '@mui/icons-material/Store';
import BuildIcon from '@mui/icons-material/Build';
import FolderIcon from '@mui/icons-material/Folder';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CategoryIcon from '@mui/icons-material/Category';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DiscountIcon from '@mui/icons-material/Discount';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

export const data = [
    {
        id: 1,
        icon: <InventoryIcon />,
        name: 'Productos',
        submenu: [
            { id: 1, title: 'Productos', url: 'productos', icon: StorefrontIcon },
            { id: 2, title: 'Odoo', url: 'productos-odoo', icon: StoreIcon },
            { id: 3, title: 'Categorías', url: 'categorias-producto', icon: CategoryIcon },
            { id: 4, title: 'Marcas', url: 'marca-producto', icon: CorporateFareIcon },
            { id: 5, title: 'Ordenamiento', url: 'orden-productos', icon: ViewModuleIcon }
        ]
    },
    {
        id: 2,
        icon: <ShoppingCartIcon />,
        name: 'E-commerce',
        submenu: [
            { id: 1, title: 'Dashboard', url: '', icon: DashboardIcon },
            { id: 2, title: 'Pedidos', url: 'orders', icon: ReceiptIcon },
            { id: 3, title: 'Costo de ventas ONL', url: 'costo-ventas', icon: PointOfSaleIcon },
            { id: 4, title: 'Carrito abandonado', url: 'carrito-abandonado', icon: ProductionQuantityLimitsIcon },
            { id: 5, title: 'Métodos de envío', url: 'metodos-envio', icon: LocalShippingIcon },
            { id: 6, title: 'Cupones', url: 'cupones', icon: LocalOfferIcon },
            { id: 7, title: 'Promociones', url: 'promociones', icon: DiscountIcon },
            { id: 8, title: 'Contacto', url: 'contacto', icon: MarkEmailUnreadIcon }
        ]
    },
    {
        id: 3,
        icon: <SettingsIcon />,
        name: 'Configuración',
        submenu: [
            { id: 1, title: 'Admins', url: 'admins', icon: AdminPanelSettingsIcon },
            { id: 2, title: 'Banners Tienda', url: 'banners-tienda', icon: ViewCarouselIcon },
            { id: 3, title: 'Extras', url: 'tools', icon: BuildIcon },
            { id: 4, title: 'Reporte Webinar', url: 'reporte-webinar', icon: ArticleIcon }
        ]
    },
    {
        id: 4,
        icon: <FolderIcon />,
        name: 'Médicos',
        submenu: [
            { id: 1, title: 'Listado', url: 'directorio-medico', icon: LocalHospitalIcon },
            { id: 2, title: 'Alta', url: 'nuevo-medico', icon: LibraryAddIcon }
        ]
    },
    {
        id: 5,
        icon: <BookIcon />,
        name: 'Blog',
        submenu: [
            { id: 1, title: 'Entradas', url: 'blog', icon: ViewComfyIcon },
            { id: 2, title: 'Nueva entrada', url: 'nuevo-blog', icon: LibraryAddIcon }
        ]
    },
    {
        id: 6,
        icon: <StoreIcon />,
        name: 'Sucursales',
        submenu: [
            { id: 1, title: 'Sucursales', url: 'sucursales', icon: StorefrontIcon },
            { id: 2, title: 'Nueva sucursal', url: 'nueva-sucursal', icon: AddBusinessIcon }
        ]
    }
]