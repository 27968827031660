import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { basicMsg, valMsg } from '../../../../actions/swal_msg';
import { saveCategory } from '../../../../actions/category';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';
import { CATEGORY_INIT } from '../../../../constants/admin/category_const';

import {
    Stack, Typography, TextField, Button, Grid,
    IconButton, List, ListItem, ListItemText,
    Box, ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import LabelIcon from '@mui/icons-material/Label';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const categoryInputError = {
    category_err: false,
    subcategory_err: false
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddCategory = ({ openDialog, setOpenDialog }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    // categorías y subcategorías
    const [mainCategory, setMainCategory] = useState(CATEGORY_INIT);
    const { img, imgFile, category, subcategory, subcategories } = mainCategory;
    // errores en campos
    const [inputErr, setInputErr] = useState(categoryInputError);
    const { category_err, subcategory_err } = inputErr;

    useEffect(() => {
        setInputErr(e => ({
            ...e,
            category_err: (
                category.length > 0 &&
                (category.length < 3 || category.length > 60)
            ) ? true : false,
            subcategory_err: (
                subcategory.length > 0 &&
                (subcategory.length < 3 || subcategory.length > 60)
            ) ? true : false
        }));
    }, [mainCategory]);

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (['category', 'subcategory'].includes(name)) {
            if (value.length <= 60)
                setMainCategory(e => ({ ...e, [name]: value.toUpperCase() }));
        }
    };

    // subir imagen de categoría
    const uploadImg = ({ target }) => {
        if (target.files.length > 0) {
            if (['image/jpeg', 'image/png', 'image/webp'].includes(target.files[0].type))
                setMainCategory(e => ({ ...e, imgFile: target.files[0] }));
            else basicMsg('Imagen no compatible');
        }
    };

    // agregar subcategoría a lista
    const addSubcategory = () => {
        if (subcategory.length > 2 && subcategory.length <= 60) {
            const subcategoryInArray = subcategories.find(e => e.toLowerCase() === subcategory.toLowerCase());
            if (subcategoryInArray)
                basicMsg('La subcategoría ya está registrada');
            else
                setMainCategory(e => ({ ...e, subcategories: [...e.subcategories, subcategory], subcategory: '' }));
        }
    };

    const formValidator = () => {
        // subcategories.length > 0
        if (category.length > 2 && category.length <= 60) {
            if (imgFile !== '')
                return true;
            else
                basicMsg('No ha subido imagen');
        } else basicMsg('Ingrese la categoría de producto');
        return false;
    };

    // guardar categoría/subcategoría(s)
    const save = () => {
        if (formValidator()) {
            setLoading(true);
            dispatch(
                saveCategory(
                    mainCategory, setMainCategory, setLoading,
                    { flag: false, navigate: () => { } }
                )
            );
        }
    };

    const removeSubcategory = subcategory => () => {
        setMainCategory((e) => ({ ...e, subcategories: e.subcategories.filter(u => u !== subcategory) }));
    };

    const matches = useMediaQuery('(max-width: 425px)')

    return (
        <Dialog
            open={openDialog}
            onClose={() => { setOpenDialog(false) }}
            maxWidth='md'
            fullWidth={true}
            sx={{ zIndex: '2' }}
        >
            <DialogTitle>Crear nueva categoría</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Box
                            component="img"
                            sx={{
                                maxHeight: matches ? '100%' : '300px',
                                maxWidth: matches ? '100%' : '300px',
                                mb: 2
                            }}
                            alt="imagen-categoria"
                            src={
                                imgFile instanceof File ?
                                    URL.createObjectURL(imgFile)
                                    :
                                    `${GOOGLE_DRIVE_CDN}${img}`
                            } />
                        <Box>
                            <Button component="label" variant="contained" sx={{ textTransform: 'none' }} startIcon={<PhotoCamera />}>
                                Subir imagen
                                <VisuallyHiddenInput accept="image/*" type="file" onChange={uploadImg} />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={2}>
                            <TextField fullWidth
                                name="category"
                                label="Categoría principal"
                                variant="outlined"
                                size="small"
                                value={category}
                                error={category_err}
                                helperText={category_err ? "Ingrese categoría" : ""}

                                onChange={handleInputChange} />
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ fontWeight: '600' }} paragraph>
                            Lista de subcategorías:
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <TextField fullWidth
                                    name="subcategory"
                                    label="Subcategoría"
                                    variant="outlined"
                                    value={subcategory}
                                    error={subcategory_err}
                                    helperText={subcategory_err ? "Ingrese subcategoría" : ""}
                                    inputProps={{ maxLength: 60 }}
                                    size="small"
                                    onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton aria-label="add" onClick={addSubcategory}>
                                    <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Box display='flex' justifyContent='flex-start' flexWrap='wrap' gap='10px' mt={3}>
                            {
                                subcategories.map((value) => (
                                    // <ListItem
                                    //     key={value}
                                    //     disableGutters
                                    //     secondaryAction={
                                    //         <IconButton aria-label="comment"
                                    //             onClick={removeSubcategory(value)}>
                                    //             <DeleteIcon />
                                    //         </IconButton>
                                    //     }>
                                    //     <ListItemIcon>
                                    //         <LabelIcon />
                                    //     </ListItemIcon>
                                    //     <ListItemText primary={value} />
                                    // </ListItem>
                                    <Chip label={value} onDelete={removeSubcategory(value)} key={value} />
                                ))
                            }
                        </Box>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setOpenDialog(false) }}>Cancelar</Button>
                <LoadingButton
                    sx={{ textTransform: 'none' }}
                    endIcon={<SaveIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    onClick={save}>
                    Guardar categoría
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddCategory;