import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import es from "date-fns/locale/es";

import ProductList from './couponSave/ProductList';
import CouponSummary from './couponSave/CouponSummary';

import {
	CouponHandleInputChange, CouponHandleSwitchChange
} from '../../../helpers/handlers/admin/coupon/couponHandler';

import { basicMsg, simpleConfirmDialog } from '../../../actions/swal_msg';
import { couponCloseModal, couponDelete, couponUpdate } from '../../../actions/coupon';
import { form1Validator } from '../../../validators/Admin/Coupon/CouponValidator';

import {
	FormGroup, Switch, FormControlLabel, Dialog,
	DialogContent, DialogTitle, Grid, DialogActions,
	TextField, Button, Typography, Stack, IconButton,
	Card, CardContent, FormControl, Radio, RadioGroup,
	InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup,
	Checkbox
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { COUPON_INIT } from '../../../constants/shop/coupon/coupon_const';

export default function CouponModal({
	// startDate, endDate, setDefaultRange,
	setDateDialog, range, setRange
}) {
	const dispatch = useDispatch();
	const { current, modal } = useSelector(state => state.coupon);

	const [coupon, setCoupon] = useState(COUPON_INIT);
	const {
		_id, _class, name, description, use_method, discount,
		available, restrictions, purchase_requirements
	} = coupon;
	const { type, _value } = discount;
	const { one_per_user, limited, quantity } = restrictions;
	const {
		active, min_purchase_amount, minimum_items_qty,
		applies_to
	} = purchase_requirements;
	const { amount } = min_purchase_amount;
	const { items_qty } = minimum_items_qty;
	const { category, product } = applies_to;
	// productos seleccionados
	const { list } = product;

	// errores en campos
	const [inputErr, setInputErr] = useState({
		name_err: false,
		discount_err: false
	});
	const { name_err, discount_err } = inputErr;

	const [touched, setTouched] = useState(false); // bandera
	
	useEffect(() => {
		const { _id, start_date, end_date } = current;
		if (_id) {
			setCoupon(e => ({
				...e, ...current
			}));
			setInputErr({
				name_err: false,
				discount_err: false
			});
			setRange([{
				startDate: new Date(start_date),
				endDate: new Date(end_date),
				key: 'selection'
			}]);
			// setDefaultRange(e => ({
			// 	...e, startDate: new Date(start_date),
			// 	endDate: new Date(end_date)
			// }));
		}
	}, [current]);

	useEffect(() => {
		const len1 = name.trim().length;
		const len2 = _value.trim().length; // descuento
		// establece errores en los campos
		setInputErr(e => ({
			...e,
			name_err: (len1 < 3 || len1 > 60)
				? true : false,
			discount_err: (
				type === 'percentage' ? (len2 < 1 || len2 > 3) ? true : false
				: len2 > 0 ? false : true
			)
		}));
	}, [name, _value]);

	useEffect(() => {
		if (use_method === 'auto')
			setCoupon(e => ({
				...e, purchase_requirements: { ...e.purchase_requirements, active: true }
			}));
	}, [use_method]);

	useEffect(() => {
		if (touched)
			setCoupon(e => ({ ...e, discount: { ...e.discount, _value: '' }}));
	}, [type]);

	useEffect(() => {
		if (!active)
			setCoupon(e => ({
				...e,
				purchase_requirements: {
					...e.purchase_requirements,
					min_purchase_amount: {
						...e.purchase_requirements.min_purchase_amount,
						active: false
					},
					minimum_items_qty: {
						...e.purchase_requirements.minimum_items_qty,
						active: false
					}
				}
			}));
	}, [active]);

	const handleInputChange = ({ target }) => {
		const name = target.name;
		const value = name === 'name' ? target.value.toUpperCase() : target.value;
		const handle = CouponHandleInputChange({ name, value }, coupon, setTouched);
		if (handle.status) setCoupon(handle.coupon);
	};

	const handleToggle = (event, _method) => {
		setCoupon(e => ({ ...e, use_method: _method }));
	};

	const handleChange = ({ target }) => {
		const handle = CouponHandleSwitchChange(target, coupon);
		if (handle.status) setCoupon(handle.coupon);
	};
	
	// actualizar cupón
	const updateFn = () => {
		const form = form1Validator(coupon, setInputErr);
		if (form.status) {
			dispatch(couponUpdate({
				...coupon,
				start_date: range[0].startDate,
				end_date: range[0].endDate
			}, setInputErr));
		} else {
			if (form.msg) basicMsg(form.msg);
		}
	};

	const deleteFn = async () => {
		if (await simpleConfirmDialog(`Deseas eliminar el cupón "${name}"?`, 'Sí, continuar'))
			dispatch(couponDelete(_id, handleClose));
	};

	// cerrar modal
	const handleClose = () => {
		dispatch(couponCloseModal());
	};

	return (
		<Dialog
			open={ modal }
			onClose={ handleClose }
			fullWidth={true}
			maxWidth='lg'
			sx={{ zIndex: 1000 }}
		>
			{/* Title modal */}
			<DialogTitle sx={{ backgroundColor: '#F5F5F5' }}>
				Editar cupón
			</DialogTitle>
			{/* Fin title modal */}
			<DialogContent sx={{ backgroundColor: '#F5F5F5' }}>
				<Grid container spacing={3}
					alignItems="center"
					justifyContent="center">
					<Grid item xs={12} md={10}>
						<Card sx={{ mb: 2 }}>
							<CardContent>
								<Typography variant="body1"
									color="text.secondary"
									gutterBottom
									sx={{ fontWeight: 'Bold' }}
								>
									Clase de cupón
								</Typography>
								<FormControl>
									<RadioGroup
										aria-labelledby="coupon-type-radio-buttons-group-label"
										name="_class"
										value={ _class }
										onChange={ handleInputChange }
									>
										<FormControlLabel value="order_discount"
											control={
												<Radio size="small" sx={{
													'&.Mui-checked': {
														color:  '#93D50A',
													},
												}} />
											}
											label="Monto de descuento de pedido" />
										{
											_class === 'order_discount' &&
												<Stack>
													<FormControl fullWidth
														variant="standard" sx={{ mb: 1 }}>
														<InputLabel id="discount-type-select-label">
															Tipo de descuento
														</InputLabel>
														<Select
															labelId="discount-type-select-label"
															id="discount-type-select"
															name="type"
															value={ type }
															label="Tipo de descuento"
															onChange={ handleInputChange }
														>
															<MenuItem value="percentage">Descuento porcentual</MenuItem>
															<MenuItem value="fixed">Descuento fijo $</MenuItem>
														</Select>
													</FormControl>
													<TextField sx={{ mb: 2 }}
														fullWidth
														name="_value"
														label={ type === 'percentage' ? 'Descuento %' : 'Descuento $' }
														variant="standard"
														error={ discount_err }
														helperText={ discount_err ? "Descuento incorrecto" : "" }
														inputProps={{ maxLength: type === 'percentage' ? 3 : 8 }}
														value={ _value }
														onChange={ handleInputChange } />
												</Stack>
										}
										<FormControlLabel value="free_shipping"
											control={
												<Radio size="small" sx={{
													'&.Mui-checked': {
														color:  '#93D50A',
													},
												}} />
											}
											label="Envío gratis" />
									</RadioGroup>
								</FormControl>
							</CardContent>
						</Card>

						<Card sx={{ mb: 2 }}>
							<CardContent>
								<Typography variant="body1"
									color="text.secondary"
									gutterBottom
									sx={{ fontWeight: 'Bold' }}
								>
									Información de cupón
								</Typography>

								<TextField sx={{ mb: 2 }}
									fullWidth
									name="name"
									label="Código de descuento"
									variant="standard"
									error={ name_err }
									helperText={ name_err ? "Ingrese nombre de cupón" : "" }
									inputProps={{ maxLength: 30 }}
									value={ name }
									onChange={ handleInputChange } />
									
								<TextField sx={{ mb: 2 }}
									fullWidth
									name="description"
									label="Descripción"
									multiline
									rows={2}
									helperText="Se muestra cuando se aplica el código"
									inputProps={{ maxLength: 100 }}
									value={ description }
									onChange={ handleInputChange } />

								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={2}
									sx={{ mb: 2 }}>
									<Typography variant="body1">
										Duración de la promoción
									</Typography>
									<IconButton onClick={() => { setDateDialog(true) }} sx={{ my: 'auto' }}>
										<CalendarMonthIcon />
									</IconButton>
								</Stack>

								<Stack
									direction="row"
									spacing={2}>
									<TextField
										size='small'
										fullWidth
										disabled
										label="Fecha de inicio"
										value={`${format(range[0].startDate, "d 'de' MMMM yyyy", { locale: es })}`} />
									<TextField
										size='small'
										fullWidth
										disabled
										label="Fecha de finalización"
										value={`${format(range[0].endDate, "d 'de' MMMM yyyy", { locale: es })}`} />
								</Stack>
							</CardContent>
						</Card>

						<Card sx={{ mb: 2 }}>
							<CardContent>
								<Typography variant="body1"
									color="text.secondary"
									gutterBottom
									sx={{ fontWeight: 'Bold' }}
								>
									Método
								</Typography>
								<ToggleButtonGroup
									size="small"
									color="primary"
									name="use_method"
									value={ use_method }
									exclusive
									onChange={ handleToggle }
									aria-label="Platform"
									sx={{ mb: 2 }}>
									<ToggleButton value="by_code">Mediante código</ToggleButton>
									<ToggleButton value="auto">Descuento automático</ToggleButton>
								</ToggleButtonGroup>

								<FormGroup>
									<FormControlLabel
										control={
											<Switch name='available'
												checked={ available }
												onChange={ handleChange }
												inputProps={{ 'aria-label': 'coupon-availability' }}
											/>
										}
										label={
											available ? 'Cupón activo' : 'Cupón deshabilitado'
										}
									/>
								</FormGroup>
							</CardContent>
						</Card>

						<Card sx={{ mb: 2 }}>
							<CardContent>
								<Typography variant="body1"
									color="text.secondary"
									gutterBottom
									sx={{ fontWeight: 'Bold' }}
								>
									Requisitos de compra
								</Typography>
								<FormGroup>
									<FormControlLabel control={
										<Switch
											name="purchase_requirements"
											checked={ active }
											onChange={ handleChange }
										/>
									} label={
										active ? 'Cumplir los requisitos siguientes:' : 'Sin requisitos'
									} />
								</FormGroup>

								<FormGroup>
									<FormControlLabel control={
										<Checkbox
											name="min_purchase_amount"
											disabled={ !active }
											checked={ min_purchase_amount.active }
											onChange={ handleChange }
										/>
									} label="Monto mínimo de compra ($)" />
									
									{
										min_purchase_amount.active &&
											<TextField sx={{ mb: 2 }}
												fullWidth
												name="amount"
												label="Monto"
												variant="standard"
												inputProps={{ maxLength: 10 }}
												value={ amount }
												onChange={ handleInputChange } />
									}

									<FormControlLabel control={
										<Checkbox
											name="minimum_items_qty"
											disabled={ !active }
											checked={ minimum_items_qty.active }
											onChange={ handleChange }
										/>
									} label="Cantidad mínima de artículos" />
									
									{
										minimum_items_qty.active &&
											<TextField sx={{ mb: 2 }}
												fullWidth
												name="items_qty"
												label="Cantidad"
												variant="standard"
												inputProps={{ maxLength: 4 }}
												value={ items_qty }
												onChange={ handleInputChange } />
									}
									
									<FormControlLabel control={
										<Checkbox
											disabled={ true }
											checked={ category.active }
										/>
									} label="Aplica al tener productos en carrito con las siguientes categorías" />

									<FormControlLabel control={
										<Checkbox
											name="product_req_active"
											disabled={ !active }
											checked={ product.active }
											onChange={ handleChange }
										/>
									} label="Aplica al tener los siguientes productos en carrito" />
									
									{
										product.active &&
											<ProductList
												list={ list }
												setCoupon={ setCoupon }
											/>
									}
								</FormGroup>
							</CardContent>
						</Card>

						<Card sx={{ mb: 2 }}>
							<CardContent>
								<Typography variant="body1"
									color="text.secondary"
									gutterBottom
									sx={{ fontWeight: 'Bold' }}
								>
									Límite de cupón
								</Typography>

								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox name="limited"
												checked={ limited }
												onChange={ handleChange }
												/>
											} label="Definir cantidad de cupones disponibles"
									/>
									
									{
										limited &&
											<TextField sx={{ mb: 2 }}
												fullWidth
												name="quantity"
												label="Cantidad"
												variant="standard"
												inputProps={{ maxLength: 4 }}
												value={ quantity }
												onChange={ handleInputChange } />
									}

									<FormControlLabel
										control={
											<Checkbox name="one_per_user"
												checked={ one_per_user }
												onChange={ handleChange }
												/>
											} label="Un uso por usuario" />
											
									<FormControlLabel
										control={
											<Checkbox disabled
												name="limited"
												checked={ false }
											/>
											} label="Cupón exclusivo de cliente (No disponible)"
									/>
									<TextField sx={{ mb: 2 }}
										fullWidth
										disabled
										name="email"
										label="Correo de cliente (deshabilitado)"
										variant="standard"
										inputProps={{ maxLength: 120 }}
									/>
								</FormGroup>
							</CardContent>
						</Card>

						<Card sx={{ mb: 2 }}>
							<CardContent>
								<Typography variant="body1"
									color="text.secondary"
									gutterBottom
									sx={{ fontWeight: 'Bold' }}
								>
									Compatibilidad con otras promociones (deshabilitado)
								</Typography>

								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox name="products"
												disabled={ true }
												checked={ true }
												onChange={ handleInputChange }
												/>
											} label="Cupón se puede combinar con promociones" />
									<FormControlLabel
										control={
											<Checkbox name="products"
												disabled={ true }
												checked={ false }
												onChange={ handleInputChange }
												/>
											} label="Cupón se puede combinar con otros cupones de descuento" />
								</FormGroup>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={10}>
						<CouponSummary coupon={ coupon } />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="standard"
					onClick={ deleteFn }>
					Eliminar
				</Button>
				<Button
					variant="standard"
					onClick={ updateFn }>
					Actualizar
				</Button>
			</DialogActions>
		</Dialog>
	);
};